import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core";

function AwakenerAcademy(props) {
    const classes = useStyles()
    useEffect(() => {
        window.addEventListener('scroll', ev => {
            const el = document.getElementById('awkenerAcademy')
            if (!el) return;
            const total = el?.offsetHeight
            const start = el?.offsetTop
            const screenHeight = window.innerHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                const content = el.querySelector("#awkenerAcademyContent")
                if (percent >= 0 && percent <= 40) {
                    content.style.opacity = 1
                    return;
                }
                if (percent > 40 && percent <= 60) {
                    let opacity = -0.05 * percent + 3
                    content.style.opacity = opacity
                    return;
                }

                if (percent >= 60) {
                    content.style.opacity = 0
                    return;
                }
            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])
    return (
        <section className={classes.root} id={"awkenerAcademy"}>
            <div className={classes.boxSticky}>
                <div className={classes.content} id={"awkenerAcademyContent"}>
                    <div className={classes.title1}>Awakener Academy</div>
                    <div className={classes.title2}>コーデミア</div>
                    <div className={classes.title3}>Meta-versity</div>
                </div>
                <img className={classes.img} src="/images/laudai.png" alt=""/>
            </div>
        </section>
    );
}

export default AwakenerAcademy;
const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        height: "300vh",
        zIndex: 3,
        backgroundColor: "#fff"
    },
    boxSticky: {
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,

    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    content: {
        position: "absolute",
        left: 0,
        right: 0,
        textAlign: "center",
        top: "50%",
        transform: "translateY(-50%)"
    },
    title1: {
        fontSize: "80pt",
        fontWeight: 900,
        WebkitTextStrokeWidth: "3px",
        WebkitTextStrokeColor: "#fff",
        [theme.breakpoints.down('md')]: {
            fontSize: "50pt",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "35pt",
            WebkitTextStrokeWidth: "2px",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "25pt",
        },
    },
    title2: {
        fontSize: "90pt",
        fontWeight: 900,
        WebkitTextStrokeWidth: "3px",
        WebkitTextStrokeColor: "#fff",
        [theme.breakpoints.down('md')]: {
            fontSize: "60pt",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "45pt",
            WebkitTextStrokeWidth: "2px",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "30pt",
        },
    },
    title3: {
        fontSize: "100pt",
        fontWeight: 900,
        [theme.breakpoints.down('md')]: {
            fontSize: "70pt",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "45pt",
            WebkitTextStrokeWidth: "2px",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "35pt",
        },
    }


}))