import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles'
import VideoTop from "../VideoTop";

function CityIn(props) {
    const classes = useStyles()

    useEffect(() => {

        window.addEventListener('scroll', ev => {
            const el = document.getElementById('city-in')
            if (!el) return;
            const total = el?.offsetHeight
            const start = el?.offsetTop
            const screenHeight = window.innerHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                const zoomImg = document.getElementById('city-zoom-1')
                const rabbit = document.getElementById('rabbit-1')
                const blackBg = document.getElementById('bg-black')
                const video = document.getElementById('city-in-video')
                // console.log(percent);
                if (percent < 5) {
                    zoomImg.style.transform = `scale(1) translateX(0%)`
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    rabbit.style.opacity = 1
                    return;
                }
                if (percent <= 15) {
                    // rabbit.style.left = "50%"
                    // rabbit.style.opacity = 1
                    const translate = (percent - 5) * 4
                    zoomImg.style.transform = `scale(1) translateX(-${translate}%)`
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    rabbit.style.opacity = 1
                    return;
                }
                if (percent >= 15 && percent <= 30) {
                    // const translate =( percent - 5) * 4
                    rabbit.style.opacity = 1
                    zoomImg.style.transform = "scale(1) translateX(-40%)"
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    return;
                }
                if (percent > 30 && percent < 80) {
                    rabbit.style.opacity = 0
                    const scale = 1 + (percent - 20) / 3.7
                    zoomImg.style.transform = `scale(${scale}) translateX(-40%)`
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    return;
                }
                if (percent >= 80 && percent < 85) {
                    rabbit.style.opacity = 0
                    zoomImg.style.transform = `scale(21) translateX(-40%)`
                    zoomImg.style.transformOrigin = "center"
                    const opacity = (percent - 80) / 5
                    blackBg.style.opacity = opacity
                    video.style.opacity = 0
                }
                if (percent >= 85) {
                    rabbit.style.opacity = 0
                    zoomImg.style.transform = `scale(21) translateX(-40%)`
                    zoomImg.style.transformOrigin = "center"
                    const opacity = (percent - 85) / 10
                    blackBg.style.opacity = 1
                    video.style.opacity = opacity >= 1 ? 1 : opacity

                }
            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])
    return (
        <div className={classes.root} id={'city-in'}>
            <div className={classes.content}>
                <img className={classes.cityImg} id={'city-zoom-1'} src={'/images/city/map111.png'} alt={'city'}/>
                <div className={classes.rabbit} id={'rabbit-1'}>
                    <img src={'/images/city/tho.png'} alt={'rabbit'}/>
                </div>
            </div>
            <div className={classes.bgBlack} id={"bg-black"}/>
            <div className={classes.video} id={'city-in-video'}>
                <VideoTop/>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: "1000vh",
        position: "relative",
        zIndex: 2,
        // marginTop: "-100vh"
    },
    content: {
        position: "sticky",
        top: 0,
        overflow: "hidden"
    },
    cityImg: {
        width: '100%',
        transition: "all 0.5s",
        maxHeight: "100vh"
    },
    rabbit: {
        position: "absolute",
        zIndex: 1,
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "& img": {
            width: "100px",
            [theme.breakpoints.down('xs')]: {
                width: "50px"
            }
        },
        transition: "all 0.5s"
    },
    bgBlack: {
        background: "#000",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
        opacity: 0
    },

    video: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
        opacity: 0
    },

}))

export default CityIn;