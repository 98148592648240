import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';

function UnconsciousSelfContradiction(props) {
    const classes = useStyles()

    useEffect(() => {

        window.addEventListener('scroll', ev => {
            const el = document.getElementById('UnconsciousSelfContradiction')
            if (!el) return;
            const total = el?.offsetHeight
            const start = el?.offsetTop
            const screenHeight = window.innerHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                // console.log(percent);
                const img = el.querySelector("img")
                const endText = el.querySelector("h3")
                const firstLabel = document.getElementById('part2-label-1')
                const secondLabel = document.getElementById('part2-label-2')
                if (percent >= 0 && percent < 15) {
                    img.style.transform = "translateX(100vw)"
                    endText.style.opacity = 0
                    firstLabel.innerHTML = "人の内面には自分では気付けてない <br/><span style='color: #472AFC'>「推進による失敗」</span>を恐れ、<br/><span style='color: #FC9B15'>「停滞による成功」</span>を望んでしまう<br/>"
                    secondLabel.innerHTML = "  が、内在している。だから人は、<br/>「進みたい」のに「進めない」のだ。"
                    firstLabel.style.opacity = 1
                    secondLabel.style.opacity = 1
                    return;
                }
                if (percent >= 15 && percent <= 20) {
                    img.style.transform = "translateX(100vw)"
                    endText.style.opacity = 0
                    firstLabel.style.opacity = 1 - (percent - 15) * 0.2
                    secondLabel.style.opacity = 1 - (percent - 15) * 0.2
                    firstLabel.innerHTML = "人の内面には自分では気付けてない <br/>「推進による失敗」を恐れ、<br/>「停滞による成功」を望んでしまう<br/>"
                    secondLabel.innerHTML = "  が、内在している。だから人は、<br/>「進みたい」のに「進めない」のだ。"
                    return;
                }
                if (percent > 20 && percent <= 25) {
                    img.style.transform = "translateX(100vw)"
                    endText.style.opacity = 0
                    firstLabel.style.opacity = (percent - 20) * 0.2
                    secondLabel.style.opacity = (percent - 20) * 0.2
                    firstLabel.innerHTML = " <br/><br/>Insight Map®を使えば、<br/>"
                    secondLabel.innerHTML = "を可視化し、ひも解き、<br/>統合することができます。"
                    return;
                }
                if (percent > 25 && percent <= 30) {
                    img.style.transform = "translateX(100vw)"
                    endText.style.opacity = 0
                    firstLabel.style.opacity = 1
                    secondLabel.style.opacity = 1
                    firstLabel.innerHTML = " <br/><br/>Insight Map®を使えば、<br/>"
                    secondLabel.innerHTML = "を可視化し、ひも解き、<br/>統合することができます。"
                    return;
                }
                if (percent > 30 && percent <= 50) {
                    endText.style.opacity = 0
                    firstLabel.style.opacity = 1
                    secondLabel.style.opacity = 1
                    firstLabel.innerHTML = " <br/><br/>Insight Map®を使えば、<br/>"
                    secondLabel.innerHTML = "を可視化し、ひも解き、<br/>統合することができます。"
                    const transform = 100 - (percent - 30) * 5
                    img.style.transform = `translateX(${transform}vw)`
                    return;
                }
                if (percent > 50 && percent <= 55) {
                    endText.style.opacity = 0
                    firstLabel.style.opacity = 1 - (percent - 50) * 0.2
                    secondLabel.style.opacity = 1 - (percent - 50) * 0.2
                    firstLabel.innerHTML = " <br/><br/>Insight Map®を使えば、<br/>"
                    secondLabel.innerHTML = "を可視化し、ひも解き、<br/>統合することができます。"
                    img.style.transform = `translateX(0vw)`
                    return;
                }
                if (percent > 55 && percent <= 75) {
                    endText.style.opacity = (percent - 50) * 0.05
                    firstLabel.style.opacity = 0
                    secondLabel.style.opacity = 0
                    firstLabel.innerHTML = " <br/><br/>Insight Map®を使えば、<br/>"
                    secondLabel.innerHTML = "を可視化し、ひも解き、<br/>統合することができます。"
                    img.style.transform = `translateX(0vw)`
                    return;
                }
                if (percent > 75) {
                    endText.style.opacity = 1
                    firstLabel.style.opacity = 0
                    secondLabel.style.opacity = 0
                    firstLabel.innerHTML = " <br/><br/>Insight Map®を使えば、<br/>"
                    secondLabel.innerHTML = "を可視化し、ひも解き、<br/>統合することができます。"
                    img.style.transform = `translateX(0vw)`
                    return;
                }
            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])
    return (
        <div className={classes.root} id={'UnconsciousSelfContradiction'}>
            <div className={classes.content}>
                <div className={classes.text}>
                    {/*<h2 className={classes.label}>Insight Map®を使えば、</h2>*/}
                    <p className={classes.firstText} id={'part2-label-1'}>
                        {/*人の内面には自分では気付けてない <br/>*/}
                        {/*「推進による失敗」を恐れ、<br/>*/}
                        {/*「停滞による成功」を望んでしまう<br/>*/}
                    </p>
                    <h1 className={classes.subTitle}>アンコンシャス・パラドクス</h1><br/>
                    <h1 className={classes.title}>&nbsp;無自覚の自己矛盾</h1><br/>
                    <p className={classes.firstText} id={'part2-label-2'}>
                        {/*が、内在している。だから人は、<br/>「進みたい」のに「進めない」のだ。*/}
                    </p>

                    {/*<h2 className={classes.label}>を可視化し、ひも解き、</h2>*/}
                    {/*<h2 className={classes.label}>統合することができます。</h2>*/}
                    <h3 className={classes.endText}>Unify The Insight</h3>
                </div>
                <div className={classes.imgContainer}>
                    <div className={classes.imgFlex}>
                        <img className={classes.img} src={"/images/map_bg@4x.png"} alt={'map-bg'}/>
                    </div>
                </div>

            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: "600vh",
        position: 'relative',
        backgroundColor: "#f9f9f9",
        marginTop: "30vh",
        [theme.breakpoints.down("xs")]: {
            marginTop: 0,
        },
    },
    content: {
        height: "100vh",
        position: "sticky",
        top: 0,
        overflow: "hidden"
    },
    text: {
        position: "absolute",
        top: "50%",
        left: "12%",
        fontWeight: 700,
        textAlign: 'left',
        zIndex: 1,
        transform: "translateY(-50%)",
        [theme.breakpoints.down("md")]: {
            left: "7%",

        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: 20,
            left: "20px",

        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0
        }
    },
    title: {
        fontSize: "90pt",
        lineHeight: 1.3,
        margin: 0,
        [theme.breakpoints.down("md")]: {
            fontSize: "60pt"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "30pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "18pt"
        },
        background: "linear-gradient(to right, rgba(20, 136, 246, 1) 0%, 13.82022500038147%, rgba(101, 4, 243, 1) 27.64045000076294%, 63.82022500038147%, rgba(254, 183, 10, 1) 100%);",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        display: "inline-block"
    },
    subTitle: {
        fontSize: "55pt",
        lineHeight: 1.3,
        margin: 0,
        [theme.breakpoints.down("md")]: {
            fontSize: "35pt"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "20pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "15pt"
        },
        background: "linear-gradient(to right, rgba(20, 136, 246, 1) 0%, 13.82022500038147%, rgba(101, 4, 243, 1) 27.64045000076294%, 63.82022500038147%, rgba(254, 183, 10, 1) 100%);",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        display: "inline-block"
    },
    label: {
        fontSize: "40pt",
        lineHeight: 1,
        transform: "translateX(100vw)",
        opacity: 0,
        transition: "all 0.5s",
        [theme.breakpoints.down("sm")]: {
            fontSize: "25pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14pt"
        }
    },
    endText: {
        fontSize: "80pt",
        lineHeight: 1,
        opacity: 0,
        position: "absolute",
        top: 0,
        [theme.breakpoints.down("md")]: {
            fontSize: "53pt"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "31pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "22pt !important"
        }
    },
    firstText: {
        fontSize: "40pt",
        lineHeight: 1.3,
        [theme.breakpoints.down("sm")]: {
            fontSize: "25pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14pt"
        }
    },
    imgContainer: {
        width: "50%",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        [theme.breakpoints.down("sm")]: {
            width: "70%"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    },
    imgFlex: {
        display: "flex",
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    img: {
        width: "100%", margin: "auto",
        transform: "translateX(100vw)"
    },

}))
export default UnconsciousSelfContradiction;
