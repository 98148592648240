import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import PopoverContent from "../PopoverContent/PopoverContent";
import VideoTop from "../VideoTop";

const useStyles = makeStyles(theme => ({
    root: {
        height: "2500vh",
        position: "relative",
        marginTop: "-100vh"
    },
    content: {
        position: "sticky",
        top: 0,
        overflow: "hidden",
        height: "100vh",
        display: "flex",
        alignItems: "center"
    },
    cityImg: {
        width: '100%',
        transition: "all 0.5s",
        maxHeight: "100vh"
    },
    rabbit: {
        position: "absolute",
        zIndex: 1,
        top: "60%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "& img": {
            width: "100px",
            [theme.breakpoints.down('xs')]: {
                width: "50px"
            }
        },
        transition: "all 0.5s"
    },
    bgBlack: {
        background: "#000",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
        opacity: 0
    },

    video: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
        opacity: 0
    },
    twopic: {
        position: "absolute",
        bottom: 0,
        left: 20,
        zIndex: 2,
        "& img": {
            width: "100px",
            [theme.breakpoints.down('xs')]: {
                width: 50
            }
        },
        opacity: 0
    },
    popper: {
        position: 'absolute',
        top: "42%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        opacity: 0,
        transition: "all 0.5s"
    }
}));

function Part12(props) {
    const classes = useStyles()

    useEffect(() => {
        window.addEventListener('scroll', ev => {
            const el = document.getElementById('city-in-2')
            if (!el) return;
            const total = el?.offsetHeight - window.innerHeight
            const start = el?.offsetTop + window.innerHeight
            const screenHeight = window.innerHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                const zoomImg = document.getElementById('city-zoom-2')
                const rabbit = document.getElementById('rabbit-2')
                const twopic = document.getElementById('part12-2pic')
                const popper = document.getElementById('part12-popper')
                const blackBg = document.getElementById('part12-bg-black')
                const video = document.getElementById('part12-city-in-video')
                // console.log(percent);
                if (percent < 3) {
                    zoomImg.style.transform = `scale(1) translateX(0%)`
                    zoomImg.style.transformOrigin = "center"
                    rabbit.style.opacity = 1
                    twopic.style.opacity = 0
                    popper.style.opacity = 0
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    return;
                }
                if (percent >= 3 && percent <= 10) {
                    zoomImg.style.transform = `scale(1) translateX(0%)`
                    zoomImg.style.transformOrigin = "center"
                    rabbit.style.opacity = 1
                    twopic.style.opacity = 0
                    popper.style.opacity = 1
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    return;
                }
                if (percent > 10 && percent < 20) {
                    twopic.style.opacity = 1
                    popper.style.opacity = 0
                    const translate = (percent - 10) * 2.7
                    zoomImg.style.transform = `scale(1) translateX(${translate}%)`
                    zoomImg.style.transformOrigin = "center"
                    rabbit.style.opacity = 1
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    return;
                }

                if (percent > 20 && percent <= 23) {
                    zoomImg.style.transform = `scale(1) translateX(27%)`
                    zoomImg.style.transformOrigin = "center"
                    rabbit.style.opacity = 1
                    popper.style.opacity = 0
                    popper.style.opacity = 0
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    return;
                }
                if (percent > 23 && percent <= 33) {
                    twopic.style.opacity = 0
                    rabbit.style.opacity = 0
                    popper.style.opacity = 0
                    const scale = 1 + (percent - 23) / 3.7
                    zoomImg.style.transform = `scale(${scale}) translateX(27%) translateY(-10%)`
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    return;
                }
                if (percent > 33 && percent < 43) {
                    twopic.style.opacity = 0
                    rabbit.style.opacity = 0
                    popper.style.opacity = 0
                    const scale = 7 - (percent - 33) / 3.6
                    zoomImg.style.transform = `scale(${scale <= 1 ? 1 : scale}) translateX(27%) translateY(-10%)`
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                }
                if (percent >= 43 && percent <= 53) {
                    rabbit.style.opacity = 1
                    twopic.style.opacity = 0
                    popper.style.opacity = 0
                    const translate = (percent - 43) * 2.7
                    zoomImg.style.transform = `scale(1) translateX(${translate >= 27 ? 0 : 27 - translate}%) `
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    return;
                }
                if (percent > 53 && percent < 55) {
                    rabbit.style.opacity = 1
                    twopic.style.opacity = 0
                    popper.style.opacity = 0
                    zoomImg.style.transform = `scale(1) translateX(0%) `
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    return;
                }
                if (percent >= 55 && percent <= 65) {
                    const translate = (percent - 65) * 4
                    zoomImg.style.transform = `scale(1) translateX(-${translate}%)`
                    rabbit.style.opacity = 1
                    twopic.style.opacity = 0
                    popper.style.opacity = 0
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    return;
                }
                if (percent > 65 && percent < 67) {
                    zoomImg.style.transform = `scale(1) translateX(-40%)`
                    rabbit.style.opacity = 1
                    twopic.style.opacity = 0
                    popper.style.opacity = 0
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                    return;
                }
                if (percent >= 67 && percent < 80) {
                    const scale = 1 + (percent - 67) / 3.7
                    zoomImg.style.transform = `scale(${scale}) translateX(-40%)`
                    rabbit.style.opacity = 0
                    twopic.style.opacity = 0
                    popper.style.opacity = 0
                    zoomImg.style.transformOrigin = "center"
                    blackBg.style.opacity = 0
                    video.style.opacity = 0
                }
                if (percent >= 80 && percent < 85) {
                    rabbit.style.opacity = 0
                    twopic.style.opacity = 0
                    popper.style.opacity = 0
                    video.style.opacity = 0
                    zoomImg.style.transform = `scale(21) translateX(-40%)`
                    zoomImg.style.transformOrigin = "center"
                    const opacity = (percent - 80) / 5
                    blackBg.style.opacity = opacity
                    return;
                }
                if (percent >= 85) {
                    rabbit.style.opacity = 0
                    zoomImg.style.transform = `scale(21) translateX(-40%)`
                    zoomImg.style.transformOrigin = "center"
                    const opacity = (percent - 85) / 9
                    blackBg.style.opacity = 1
                    video.style.opacity = opacity >= 1 ? 1 : opacity
                }

            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])
    return (
        <div className={classes.root} id={'city-in-2'}>
            <div className={classes.content}>
                <img className={classes.cityImg} id={'city-zoom-2'} src={'/images/city/map111.png'} alt={'city2'}/>
                <div className={classes.rabbit} id={'rabbit-2'}>
                    <img src={'/images/city/tho.png'} alt={'rabbit'}/>
                </div>
                <div className={classes.twopic} id={'part12-2pic'}>
                    <img alt={'pic'} src={'/images/city/2pic.png'}/>
                </div>
                <div className={classes.popper} id={'part12-popper'}>
                    <PopoverContent/>
                </div>
                <div className={classes.bgBlack} id={"part12-bg-black"}/>
                <div className={classes.video} id={'part12-city-in-video'}>
                    <VideoTop/>
                </div>
            </div>
        </div>
    );
}

export default Part12;