import React, {useEffect} from 'react';
import "./index.css"
import {useMediaWidth} from "../../context/mediaQuery";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        // maxHeight: "860px",
        margin: "auto"
    },
    line: {
        textAlign: "center",
        fontWeight: "bold",
        margin: 10,
        // opacity: 0,
        transition: "all 1.5s",
        // transform: "translateY(100vh)",
        [theme.breakpoints.down('md')]: {
            marginBottom: 50
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10
        },
    },
    lineMid: {
        textAlign: "center",
        fontWeight: "bold",
        marginTop: 10,
        // opacity: 0,
        transition: "all 1.5s",
        fontSize: '70.6pt',
        color: '#FFFFFF',
        "& p": {
            margin: 0
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '50pt',
            marginTop: 50
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '40pt',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '25pt',
        },
    },
    lineSub: {
        fontSize: '55.6pt',
        marginTop: "10px",
        [theme.breakpoints.down('md')]: {
            fontSize: '35pt',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '25pt',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '15pt',
        },
    },
    box: {
        height: "100vh",
        position: "sticky",
        top: 0,
        zIndex: 999,
        width: "100%"
    },
    content: {
        opacity: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        zIndex: 999,
        fontSize: '101.6pt',
        fontWeight: 'bold',
        color: '#FFFFFF',
        border: "transparent",
        "& p": {
            margin: 0
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '60pt',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '45pt',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '25pt',
        },
    }
}))

function VideoTop(props) {
    const width = useMediaWidth();
    const classes = useStyles();

    setTimeout(() => {
        const el = document.getElementById('video-top-content-text');
        if (el) {
            el.style.opacity = 1;
            el.style.transition = "all 1.5s"
        }
    }, 1000)

    useEffect(() => {
        const element = document.getElementById('video-top-bg')
        if (!element)
            return;

        const sourceMp4 = document.getElementById("video-top-bg-mp4")
        const sourceWebm = document.getElementById("video-top-bg-webm")

        sourceMp4.setAttribute("src", `/videos/insight-mind-${width}.mp4`)
        sourceWebm.setAttribute("src", `/videos/insight-mind-${width}.webm`)

        element.load();
        element.play();
        // console.log('play video done!')
    }, [width])

    return (
        <div className={classes.root} {...props}>
            <div className={classes.box}>
                <div id={'video-top-content-text'} className={classes.content}>
                    <div className={classes.line}>Unify The Insight</div>
                    <div className={classes.line}>
                        <div className={classes.lineMid}>変革のジレンマ</div>
                        <div className={classes.lineSub}>なぜ人は<br/>「進みたい」のに「進めない」のか？</div>
                    </div>
                </div>
                <div
                    className={"videoWrapper"}
                    id={'videos-top'}
                >
                    <video
                        id={'video-top-bg'}
                        className={"imgBg"}
                        poster={`/videos/insight-mind-${width}.jpg`} preload="auto" autoPlay loop muted
                        playsInline={true}
                    >
                        <source id={"video-top-bg-webm"} src={`/videos/insight-mind-${width}.webm`} type='video/webm'/>
                        <source id={"video-top-bg-mp4"} src={`/videos/insight-mind-${width}.mp4`} type='video/mp4'/>
                    </video>
                </div>
            </div>
        </div>
    );
}

export default VideoTop;
