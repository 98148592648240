import React from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Grid} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';

function Profile(props) {
    const classes = useStyles()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <section className={classes.root}>
            <div className={classes.sticky}>
                <h2 className={classes.title}><span>講師紹介</span></h2>
                <Grid container
                      className={classes.container}
                      alignItems="flex-end"
                >
                    <Grid item xs={12} sm={12} md={3}>
                        <div className={classes.info}>
                            <img className={classes.imgInfo} src="/images/author1.png" alt=""/>
                            <div className={classes.boxInfo}>
                                <h4 className={classes.position}>株式会社Andom<br/>代表取締役</h4>
                                <h3 className={classes.name}>生田 知久</h3>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <div className={classes.details}>
                            <h3 className={classes.nameDetail}>理論開発者 兼 講師</h3>
                            <div className={classes.content}>
                                <div className={classes.listBooks}>
                                    <img src="/images/book1.svg" alt=""/>
                                    <img src="/images/book2.svg" alt=""/>
                                    <img src="/images/book3.svg" alt=""/>
                                </div>
                                <p>
                                    2002年 横浜国立大学を卒業後、研修事業にて起業。延べ10万人に研修を提供。著書は世界４カ国で翻訳され、累計10万部を突破。
                                </p>
                                <p> 2012年 自己統合の技術「アイミング」を発明。被験者として、自分自身を自己統合した結果「発明家」こそが、究極の「自己一致した自己」だと気付く。
                                    その後、社会と隔絶し発明に没頭する。その３年間はほぼ毎日、自身のエネルギーが最高に高まるディズニーシー内のカフェで研究開発をする。</p>
                                <p> その結果、Enecolor、インサイトマップなど100以上の研修理論や、技術、アプリの発明に至る。</p>
                                <p>これらの理論を応用し、世界初の１on1セッションAIのアルゴリズムを開発。そして、コンテンツの世界観を反映するキャラクターなどのアートディレクションも行っている。</p>
                                <p>また、文部科学省の国家プロジェクトにおいて研修の設計と提供、上場企業経営者への1on1セッションの提供も行っている。</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid container
                      className={classes.container}
                      direction={matches && "column-reverse"}
                      alignItems="flex-end"
                >
                    <Grid item xs={12} sm={12} md={9}>
                        <div className={classes.details}>
                            <h3 className={classes.nameDetail2}>ファシリテーター</h3>
                            <div className={classes.content}>
                                <div className={classes.listBooks2}>
                                    <img src="/images/book4.svg" alt=""/>
                                </div>
                                <p>
                                    横浜国立大学卒業後、株式会社リクルートに入社
                                </p>
                                <p>その後、株式会社ジャパンビジネスラボに参画。</p>
                                <p>常務取締役 兼キャリアデザインスクール我究館館長として12年間で4000人の受講生に講義・コーチング行う。</p>
                                <p>また、企業や教育機関へのコンサルティング業務や、講演会や研修を通して50000人にキャリアデザインのカリキュラムを提供</p>
                                <p>著書に『絶対内定』シリーズ（2011-2020）。累計100万部。 </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <div className={classes.info}>
                            <img className={classes.imgInfo} src="/images/author2.png" alt=""/>
                            <div className={classes.boxInfo}>
                                <h4 className={classes.position}>株式会社Andom<br/>パートナー</h4>
                                <h3 className={classes.name}>熊谷智宏</h3>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#f1f1f1",
        margin: "20px 0",
        padding: "0px 20px 50px 20px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
        boxSizing: "border-box",
        height: "300vh",
        [theme.breakpoints.down('sm')]: {
            minHeight: "unset",
            height: "unset"

        }
    },

    sticky: {
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        // height: "100vh",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            position: "static"
        },
    },
    title: {
        margin: "0 0 50px 0",
        textAlign: "center",
        fontSize: "36px",
        fontWeight: 900,
        // position: "absolute",
        // top: 0,
        // left: 0,
        // right: 0,
        "& span": {
            backgroundColor: " #fff",
            padding: "0 60px 20px 60px",
            borderRadius: "0 0 25px 25px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            "& span": {
                padding: "0 25px 10px 25px",
                borderRadius: "0 0 25px 25px",
            },
        }
    },
    position: {
        margin: 0,
        fontWeiggt: 900,
        fontSize: 20,
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
    name: {
        margin: 0,
        fontWeight: 900,
        fontSize: 60,
        lineHeight: 1.3,
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 22,
        }

    },
    boxInfo: {
        background: "linear-gradient(324.11deg, #D2DAE2 1.35%, #EAF9FF 43.52%, #FFFFFF 96.38%)",
        padding: "7px 0",
        borderRadius: "20px",
        maxWidth: 470,
        margin: "auto"
    },
    imgInfo: {
        maxWidth: "90%",
        margin: "auto",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "80%",
        }
    },
    container: {
        maxWidth: 1700,
        margin: "20px auto",
        [theme.breakpoints.down('sm')]: {
            "& > div": {
                width: "100%",
            }
        }
    },
    details: {
        position: "relative",
        paddingTop: 150,
        [theme.breakpoints.down('lg')]: {
            paddingTop: 130,
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 60
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 30
        },
    },
    nameDetail: {
        color: "#043951",
        margin: 0,
        fontWeight: 900,
        fontSize: 50,
        textAlign: "left",
        marginBottom: 15,
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "22px !important",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "18px !important",
        }
    },
    nameDetail2: {
        color: "#043951",
        margin: 0,
        fontWeight: 900,
        fontSize: 50,
        textAlign: "right",
        marginBottom: 15,
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "22px !important",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "18px !important",
        }
    },
    content: {
        padding: "30px 30px",
        backgroundColor: "#fff",
        borderRadius: "34px",
        textAlign: "left",
        position: "relative",
        fontSize: 18,
        color: "#444444",
        fontWeight: 900,
        [theme.breakpoints.down('sm')]: {
            padding: 20,
            fontSize: 16,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
    listBooks: {
        position: "absolute",
        right: "30px",
        bottom: "100%",
        marginBottom: -30,
        "& img": {
            margin: 5,
            maxWidth: 130,
        },
        [theme.breakpoints.down('lg')]: {
            "& img": {
                maxWidth: 130,
            },
        },
        [theme.breakpoints.down('md')]: {
            "& img": {
                maxWidth: 100,
            },
        },
        [theme.breakpoints.down('sm')]: {
            "& img": {
                maxWidth: 80,
            },
        },
        [theme.breakpoints.down('xs')]: {
            right: 10,
            "& img": {
                margin: 2,
                maxWidth: 50,
            },
        },
    },
    listBooks2: {
        position: "absolute",
        left: "30px",
        bottom: "100%",
        marginBottom: -30,
        "& img": {
            maxWidth: 130,
        },
        [theme.breakpoints.down('lg')]: {
            "& img": {
                maxWidth: 130,
            },
        },
        [theme.breakpoints.down('md')]: {
            "& img": {
                maxWidth: 100,
            },
        },
        [theme.breakpoints.down('sm')]: {
            "& img": {
                maxWidth: 80,
            },
        },
        [theme.breakpoints.down('xs')]: {
            left: 10,
            "& img": {
                maxWidth: 50,
                margin: 0,
            },
        },
    },
    info: {
        margin: "0 30px"
    }

}))

export default Profile;