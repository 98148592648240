import React from 'react';
import {Grid, makeStyles} from "@material-ui/core";

function PopoverContent(props) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.title}>タイトル</div>
            <Grid container>
                <Grid item xs={6}>
                    <img className={classes.img} src="/images/laudai.png" alt=""/>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.content}>
                        ああああああああああああああ
                        ああああああああああああああ
                        ああああああああああああああああ

                    </div>
                </Grid>
            </Grid>
            <div className={classes.arrowDown1}></div>
            <div className={classes.arrowDown2}></div>
        </div>
    );
}

export default PopoverContent;
const useStyles = makeStyles(theme => ({
    root: {
        width: 400,
        border: "solid 2px #000",
        borderRadius: "15px",
        position: "relative",
        backgroundColor: "#fff",
        padding: 5,
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            width: "280px"
        }
    },
    title: {
        fontSize: "16pt",
        fontWeight: "bold",
        margin: 0,
        padding: "10px 0",
        [theme.breakpoints.down('sm')]: {
            fontSize: "14pt",
            padding: "3px 0"
        }
    },
    img: {
        width: "100%",
        height: "100px",
        boxSizing: "border-box",
        padding: 5,
        [theme.breakpoints.down('sm')]: {
            height: 80,
        }
    },
    content: {
        boxSizing: "border-box",
        padding: 5,
        lineHeight: 1.4,
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px"
        }
    },
    arrowDown1: {
        width: 0,
        height: 0,
        borderLeft: "20px solid transparent",
        borderRight: "20px solid transparent",
        borderTop: "20px solid #fff",
        position: "absolute",
        top: "100%",
        marginTop: "0px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 2
    },
    arrowDown2: {
        width: 0,
        height: 0,
        borderLeft: "21px solid transparent",
        borderRight: "21px solid transparent",
        borderTop: "21px solid #000",
        position: "absolute",
        top: "100%",
        marginTop: "0px",
        left: "50%",
        transform: "translateX(-50%)",
    }
}))