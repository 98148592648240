import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";

function CityOut(props) {
    const classes = useStyles()
    useEffect(() => {

        window.addEventListener('scroll', ev => {
            const el = document.getElementById('city-out')
            if (!el) return;
            const total = el?.offsetHeight
            const start = el?.offsetTop
            const screenHeight = window.innerHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                // setup prev section
                const topBg = document.getElementById('part9-bg')
                topBg.style.opacity = 1;
                const percent = Math.round(scrollItem / scrollValid * 100)
                // console.log(percent);
                const bg = document.getElementById('city-out-bg')
                const img = document.getElementById('city-out-img')
                const rabbit = document.getElementById('rabbit-3')
                const pick3 = document.getElementById('3picture')
                const pick4 = document.getElementById('4picture')
                const pick5 = document.getElementById('5picture')
                const pickall = document.getElementById('fullpicture')
                if (percent < 5) {
                    rabbit.style.opacity = 0
                    bg.style.opacity = 1 - percent / 5
                    img.style.transform = "scale(18) translateX(-40%)"
                    pick3.style.opacity = 0
                    pick4.style.opacity = 0
                    pick5.style.opacity = 0
                    pickall.style.opacity = 0
                    return;
                }
                if (percent >= 5 && percent <= 40) {
                    const scale = 18 - (percent - 5) / 2
                    img.style.transform = `scale(${scale <= 1 ? 1 : scale}) translateX(-40%)`
                    bg.style.opacity = 0
                    pick3.style.opacity = 0
                    pick4.style.opacity = 0
                    pick5.style.opacity = 0
                    pickall.style.opacity = 0
                    return;
                }
                if (percent > 40 && percent <= 43) {
                    rabbit.style.opacity = 1
                    img.style.transform = `scale(1) translateX(-40%)`
                    bg.style.opacity = 0
                    pick3.style.opacity = 0
                    pick4.style.opacity = 0
                    pick5.style.opacity = 0
                    pickall.style.opacity = 0
                    return;
                }
                if (percent > 43 && percent <= 63) {
                    rabbit.style.opacity = 1
                    const translate = (percent - 43) * 2
                    img.style.transform = `scale(1) translateX(-${translate >= 40 ? 0 : 40 - translate}%)`
                    bg.style.opacity = 0
                    pick3.style.opacity = 0
                    pick4.style.opacity = 0
                    pick5.style.opacity = 0
                    pickall.style.opacity = 0
                    return;
                }
                if (percent > 63 && percent <= 65) {
                    rabbit.style.opacity = 1
                    img.style.transform = `scale(1) translateX(0%)`
                    bg.style.opacity = 0
                    pick3.style.opacity = 0
                    pick4.style.opacity = 0
                    pick5.style.opacity = 0
                    pickall.style.opacity = 0
                    return;
                }
                if (percent > 65 && percent < 71) {
                    rabbit.style.opacity = 1
                    img.style.transform = `scale(1) translateX(0%)`
                    pick3.style.opacity = 1
                    pick4.style.opacity = 0
                    pick5.style.opacity = 0
                    pickall.style.opacity = 0
                    return;
                }
                if (percent >= 71 && percent < 77) {
                    rabbit.style.opacity = 1
                    img.style.transform = `scale(1) translateX(0%)`
                    pick3.style.opacity = 0
                    pick4.style.opacity = 1
                    pick5.style.opacity = 0
                    pickall.style.opacity = 0
                    return;
                }
                if (percent >= 77 && percent < 83) {
                    rabbit.style.opacity = 1
                    img.style.transform = `scale(1) translateX(0%)`
                    pick3.style.opacity = 0
                    pick4.style.opacity = 0
                    pick5.style.opacity = 1
                    pickall.style.opacity = 0
                    return;
                }

                if (percent >= 83) {
                    rabbit.style.opacity = 1
                    img.style.transform = `scale(1) translateX(0%)`
                    pick3.style.opacity = 0
                    pick4.style.opacity = 0
                    pick5.style.opacity = 0
                    pickall.style.opacity = 1
                    return;
                }

            } else {
                const bg = document.getElementById('city-out-bg')
                bg.style.opacity = 1
            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])
    return (
        <div className={classes.root} id={'city-out'}>
            <div className={classes.content}>
                <div className={classes.contentImg}>
                    <img alt={'city'} src={'/images/city/map111.png'} id={'city-out-img'}/>
                </div>
                <div className={classes.bg} id={'city-out-bg'}/>
                <div className={classes.rabbit} id={'rabbit-3'}>
                    <img src={'/images/city/tho.png'} alt={'rabbit3'}/>
                </div>

                <div>
                    <img className={classes.imageMore} id={'3picture'} src={'/images/city/3pic.png'} alt={'3pic'}/>
                    <img className={classes.imageMore} id={'4picture'} src={'/images/city/4pic.png'} alt={'4pic'}/>
                    <img className={classes.imageMore} id={'5picture'} src={'/images/city/5pic.png'} alt={'5pic'}/>
                    <img className={classes.imageMore} id={'fullpicture'} src={'/images/city/fullpic.png'}
                         alt={'fullpic'}/>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: "2000vh",
        position: "relative",
        zIndex: 1
    },
    imageMore: {
        position: "absolute",
        zIndex: 2,
        bottom: 10,
        left: 20,
        height: 80,
        textAlign: 'left',
        opacity: 0,
        transition: "all 0.5",
        [theme.breakpoints.down('sm')]: {
            height: "45px",
            bottom: 30,
        }
    },
    rabbit: {
        position: "absolute",
        zIndex: 2,
        top: "60%",
        left: "50%",
        opacity: 0,
        transform: "translate(-50%, -50%)",
        "& img": {
            width: "100px",
            [theme.breakpoints.down('xs')]: {
                width: "50px"
            }
        },
        transition: "all 0.5s"
    },
    content: {
        position: 'sticky',
        top: 0,
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        alignItems: "center"
    },
    contentImg: {
        width: "100%",
        "& img": {
            width: "100%",
            transition: "all 0.5s",
            maxHeight: "100vh",
            transform: "scale(20) translateX(-40%)"
        },
    },
    bg: {
        position: "absolute",
        background: "#000",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 1
    }
}))

export default CityOut;