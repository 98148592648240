import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core";

function InsightMap(props) {
    const classes = useStyles()

    useEffect(() => {
        window.addEventListener('scroll', ev => {
            const el = document.getElementById('insightMap')
            if (!el) return;
            const total = el?.offsetHeight
            const start = el?.offsetTop
            const screenHeight = window.innerHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                const content1 = el.querySelector("#content1")
                const content2 = el.querySelector("#content2")
                const img = el.querySelector("img")
                if (percent >= 0 && percent <= 25) {
                    let translate = percent <= 0 ? "translateX(100vw)" : `translateX(${100 - ((percent) * 4)}vw)`
                    img.style.transform = translate
                    content1.style.opacity = 1
                    return;
                }
                if (percent > 25 && percent <= 55) {
                    let opacity = 1 - ((percent - 25) * 3 / 100)
                    img.style.transform = "translateX(0)"
                    content1.style.opacity = opacity
                    content2.style.opacity = 0
                    return;
                }
                if (percent > 55 && percent <= 90) {
                    img.style.transform = "translateX(0)"
                    let opacity = (percent - 55) * 3 / 100
                    content1.style.opacity = 0
                    content2.style.opacity = opacity
                    return;
                }
                if (percent > 90) {
                    content1.style.opacity = 0
                    content2.style.opacity = 1
                    img.style.transform = "translateX(0)"
                    return;
                }
            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])

    return (
        <section className={classes.root} id={"insightMap"}>
            <div className={classes.boxSticky}>
                <h2 className={classes.title}>Insight Map®</h2>
                <div className={classes.boxContent}>
                    <section id={"content1"} className={classes.content1}>
                        <p className={classes.text}>&nbsp;&nbsp;人は意思決定をする際に、</p>
                        <p className={classes.text}><span>「無自覚に採択している予測シナリオ」</span>があり、</p>
                        <p className={`${classes.text} ${classes.brLine}`}>&nbsp;&nbsp;これに基づいて「最適解」を見つけようとします。</p>
                        <p className={classes.text}>&nbsp;&nbsp;ここで問題になるのは<span>「予測シナリオの不適切な採択」</span>です。</p>
                        <p className={`${classes.text} ${classes.brLine}`}>&nbsp;&nbsp;その原因には、以下のようなものがあります。</p>
                        <p className={classes.text}>&nbsp;&nbsp;1, <span>一元論的</span>に、１つのシナリオに囚われている。</p>
                        <p className={classes.text}>&nbsp;&nbsp;2, <span>視野が狭く</span>、見えているシナリオに漏れがある。</p>
                        <p className={classes.text}>&nbsp;&nbsp;3, <span>論理飛躍</span>により、ほぼあり得ないシナリオを想定している。</p>
                        <p className={classes.text}>&nbsp;&nbsp;4, <span>潜在的な望みと恐れ</span>が、潜在的ゆえに考慮できていない。</p>
                        <p className={classes.text}>&nbsp;&nbsp;5, <span>認知バイアス</span>が、シナリオの評価を歪めている。</p>
                    </section>
                    <section id={"content2"} className={classes.content2}>
                        <p className={classes.text}>&nbsp;&nbsp;インサイトマップを使いこなすと、</p>
                        <p className={classes.text}><span>「無自覚の予測シナリオ」</span>を理解し、</p>
                        <p className={classes.text}><span>「無自覚の認知バイアス」</span>を可視化し、</p>
                        <p className={classes.text}><span>「無自覚の内在自己」</span>を特定できます。</p>
                        <p className={`${classes.text}`}>&nbsp;&nbsp;そして、これらによって起きている</p>
                        <div>
                            <p className={`${classes.text} ${classes.gradientSmall}`}>&nbsp;&nbsp;アンコンシャス・パラドクス</p>
                        </div>
                        <div>
                            <p className={`${classes.text} ${classes.gradientBig}`}>無自覚の自己矛盾</p>
                        </div>
                        <p className={classes.text}>&nbsp;&nbsp;を、根本からひも解くことで<span
                            className={classes.orange}>「自己受容」</span>し、</p>
                        <p className={classes.text}>&nbsp;&nbsp;物事の陰と陽の側面を<span className={classes.orange}>「中庸的」</span>に捉え、
                        </p>
                        <p className={classes.text}>&nbsp;&nbsp;俯瞰視点から<span className={classes.orange}>「統合的な最適解」</span>を導き、
                        </p>
                        <p className={classes.text}><span className={classes.orange}>「実行プロセス」</span>に落とすことができます。</p>
                    </section>
                </div>
                <div className={classes.imgContainer}>
                    <div className={classes.imgFlex}>
                        <img className={classes.img} src={"/images/map_bg@4x.png"} alt={'map-bg'}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InsightMap;
const useStyles = makeStyles(theme => ({
    root: {
        height: "500vh",
        position: 'relative',
        background: "#f9f9f9",
        "& span": {
            color: "blue"
        },
        zIndex: 99,
        [theme.breakpoints.down("xs")]: {
            height: "300vh",
        }
    },
    boxSticky: {
        height: "100vh",
        position: "sticky",
        top: 0,
    },
    boxContent: {
        position: "absolute",
        top: 0,
        left: "12%",
        fontWeight: 700,
        textAlign: 'left',
        zIndex: 1,
        width: "80%",
        [theme.breakpoints.down('sm')]: {
            left: 0,
            width: "100%",
        },
        [theme.breakpoints.down("xs")]: {
            top: "15%"
        }
        // transform: "translateY(-50%)",
    },
    text: {
        margin: "0 0 10px 0",
        fontSize: 25,
        paddingLeft: 60,
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            paddingLeft: 0,
            marginBottom: 5,
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: 15,
        }
    },
    title: {
        fontSize: 50,
        margin: 0,
        padding: 40,
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
            padding: 15,
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: 40,
        }
    },
    content1: {
        position: "absolute",
        opacity: 1,
        transform: "translateY(50%)",
        top: "0%",
        [theme.breakpoints.down('sm')]: {
            top: "70px",
            transform: "unset"
        },
        [theme.breakpoints.down("xs")]: {
            top: "50%"
        }
    },
    content2: {
        position: "absolute",
        opacity: 0,
        transform: "translateY(50%)",
        top: 0,
        [theme.breakpoints.down('sm')]: {
            top: "70px",
            transform: "unset"
        },
    },
    gradientSmall: {
        background: "linear-gradient(to right, rgba(20, 136, 246, 1) 0%, 13.82022500038147%, rgba(101, 4, 243, 1) 27.64045000076294%, 63.82022500038147%, rgba(254, 183, 10, 1) 100%);",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        display: "inline-block"
    },
    gradientBig: {
        background: "linear-gradient(to right, rgba(20, 136, 246, 1) 0%, 13.82022500038147%, rgba(101, 4, 243, 1) 27.64045000076294%, 63.82022500038147%, rgba(254, 183, 10, 1) 100%);",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        display: "inline-block",
        fontSize: 50,
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
    },
    orange: {
        color: "#ff7600 !important",
    },
    brLine: {
        marginBottom: 50,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 25,
        },
    },
    imgContainer: {
        width: "50%",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        [theme.breakpoints.down("sm")]: {
            width: "70%"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    },
    imgFlex: {
        display: "flex",
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    img: {
        width: "100%",
        margin: "auto",
        transform: "translateX(100vw)",
        zIndex: -1
    },
}))