import React from 'react';
import {makeStyles} from "@material-ui/core";

function DivTextMemo({value}) {
    const classes = useStyles();

    return (
        <div className={classes.text}>
            <div style={{color: value.color}}>{value.id} {value.engText}</div>
            <div>{value.secondary}</div>
        </div>
    );
}


const useStyles = makeStyles(theme => ({
    text: {
        fontSize: 45,
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
            maxWidth: 150
        },
    }
}))

function areEqual(prevProps, nextProps) {
    return prevProps.value.id === nextProps.value.id
}

export default React.memo(DivTextMemo, areEqual);