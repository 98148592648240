import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import DivTextMemo from "./DivTextMemo";

const TextSteps = [
    {
        id: 1,
        engText: 'Conflict',
        primary: "内面の理解",
        secondary: "矛盾・葛藤・対立・分離",
        color: "#70c3ed"
    },
    {
        id: 2,
        engText: 'Story',
        primary: "内面の理解",
        secondary: "内面の物語と未来の想定",
        color: "#6783c2"
    },
    {
        id: 3,
        engText: 'Border',
        primary: "内面の理解",
        secondary: "顕在領域と潜在領域",
        color: "#756bb0"
    },
    {
        id: 4,
        engText: 'Energy',
        primary: "内面の理解",
        secondary: "思考・感情・気・身体",
        color: "#9a6daf"
    },
    {
        id: 5,
        engText: 'Bias',
        primary: "自己の理解",
        secondary: "認知バイアスと思想の流れ",
        color: "#f15b40"
    },
    {
        id: 6,
        engText: 'Identity',
        primary: "自己の理解",
        secondary: "内的自己と他者",
        color: "#d671ad"
    },
    {
        id: 7,
        engText: 'Clear',
        primary: "自己の理解",
        secondary: "充分と解決",
        color: "#f15f90"
    },
    {
        id: 8,
        engText: 'Block',
        primary: "自己の理解",
        secondary: "心理障壁と現実の壁",
        color: "#fab64e"
    },
    {
        id: 9,
        engText: 'Choose the Identity',
        primary: "自己の実現",
        secondary: "主体・役割・在り方の選択",
        color: "#f2e51c"
    },
    {
        id: 10,
        engText: 'Keys',
        primary: "自己の実現",
        secondary: "変革の鍵",
        color: "#aed361"
    },
    {
        id: 11,
        engText: 'Process',
        primary: "自己の実現",
        secondary: "実現プロセス",
        color: "#8ec975"
    },
    {
        id: 12,
        engText: 'Task',
        primary: "自己の実現",
        secondary: "習慣と行動",
        color: "#8ed0b5"
    },
]

function InsightMapTree(props) {
    const classes = useStyles();
    const [step, setStep] = useState(0)
    // const width = useMediaWidth() || null;
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const changeStep = useCallback((value) => {
        setStep(value)
    })

    useEffect(() => {
        window.addEventListener('scroll', ev => {
            const el = document.getElementById('insight-map-tree')
            if (!el) return;
            let total = el?.offsetHeight
            let start = el?.offsetTop
            const screenHeight = window.innerHeight
            const content = el.querySelector("#insight-map-tree-content")
            start = start + screenHeight
            total = total - screenHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                const img1 = el.querySelector("#insight-map-tree-img-1");
                const img2 = el.querySelector("#insight-map-tree-img-2");
                const img3 = el.querySelector("#insight-map-tree-img-3");
                const img4 = el.querySelector("#insight-map-tree-img-4");
                const img5 = el.querySelector("#insight-map-tree-img-5");
                const img6 = el.querySelector("#insight-map-tree-img-6");
                const img7 = el.querySelector("#insight-map-tree-img-7");
                const img8 = el.querySelector("#insight-map-tree-img-8");
                const img9 = el.querySelector("#insight-map-tree-img-9");
                const img10 = el.querySelector("#insight-map-tree-img-10");
                const img11 = el.querySelector("#insight-map-tree-img-11");
                const img12 = el.querySelector("#insight-map-tree-img-12");
                const textBigAbsolute = el.querySelector("#textBigAbsolute");

                // content.style.transition = "1.5s all";
                content.style.zIndex = 9;
                if (percent) {
                    if (percent >= 0 && percent < 10) {
                        // img.style.transform = `translateX(${70 - (percent * 15) > 0 ? 70 - (percent * 15) : 0}vw)`
                        // img.style.transition = "1.5s all";
                        img1.style.opacity = 0;
                        img2.style.opacity = 0;
                        img3.style.opacity = 0;
                        img4.style.opacity = 0;
                        img5.style.opacity = 0;
                        img6.style.opacity = 0;
                        img7.style.opacity = 0;
                        img8.style.opacity = 0;
                        img9.style.opacity = 0;
                        img10.style.opacity = 0;
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        textBigAbsolute.style.opacity = 0;
                        return;
                    }

                    if (percent > 10 && percent <= 17) {
                        img1.style.opacity = 1
                        img1.style.transition = "1.5s all";
                        img2.style.opacity = 0;
                        img3.style.opacity = 0;
                        img4.style.opacity = 0;
                        img5.style.opacity = 0;
                        img6.style.opacity = 0;
                        img7.style.opacity = 0;
                        img8.style.opacity = 0;
                        img9.style.opacity = 0;
                        img10.style.opacity = 0;
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        textBigAbsolute.style.opacity = 1;
                        changeStep(1)
                        return;
                    }
                    if (percent > 17) {
                        img1.style.opacity = 1
                    }
                    if (percent > 17 && percent <= 24) {
                        img2.style.opacity = 1
                        img2.style.transition = "1.5s all";
                        img3.style.opacity = 0;
                        img4.style.opacity = 0;
                        img5.style.opacity = 0;
                        img6.style.opacity = 0;
                        img7.style.opacity = 0;
                        img8.style.opacity = 0;
                        img9.style.opacity = 0;
                        img10.style.opacity = 0;
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        changeStep(2)
                        return;
                    }
                    if (percent > 24) {
                        img2.style.opacity = 1
                    }
                    if (percent > 24 && percent <= 31) {
                        img3.style.opacity = 1
                        img3.style.transition = "1.5s all";
                        img4.style.opacity = 0;
                        img5.style.opacity = 0;
                        img6.style.opacity = 0;
                        img7.style.opacity = 0;
                        img8.style.opacity = 0;
                        img9.style.opacity = 0;
                        img10.style.opacity = 0;
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        changeStep(3)
                        return;
                    }
                    if (percent > 31) {
                        img3.style.opacity = 1
                    }
                    if (percent > 31 && percent <= 38) {
                        img4.style.opacity = 1
                        img4.style.transition = "1.5s all";
                        img5.style.opacity = 0;
                        img6.style.opacity = 0;
                        img7.style.opacity = 0;
                        img8.style.opacity = 0;
                        img9.style.opacity = 0;
                        img10.style.opacity = 0;
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        changeStep(4)
                        return;
                    }
                    if (percent > 38) {
                        img4.style.opacity = 1
                    }
                    if (percent > 38 && percent <= 45) {
                        img5.style.opacity = 1
                        img5.style.transition = "1.5s all";
                        img6.style.opacity = 0;
                        img7.style.opacity = 0;
                        img8.style.opacity = 0;
                        img9.style.opacity = 0;
                        img10.style.opacity = 0;
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        changeStep(5)
                        return;
                    }
                    if (percent > 45) {
                        img5.style.opacity = 1
                    }
                    if (percent > 45 && percent < 52) {
                        img6.style.opacity = 1
                        img6.style.transition = "1.5s all";
                        img7.style.opacity = 0;
                        img8.style.opacity = 0;
                        img9.style.opacity = 0;
                        img10.style.opacity = 0;
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        changeStep(6)
                        return;
                    }
                    if (percent > 52) {
                        img6.style.opacity = 1
                    }
                    if (percent > 52 && percent <= 59) {
                        img7.style.opacity = 1
                        img7.style.transition = "1.5s all";
                        img8.style.opacity = 0;
                        img9.style.opacity = 0;
                        img10.style.opacity = 0;
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        changeStep(7)
                        return;
                    }
                    if (percent > 59) {
                        img7.style.opacity = 1
                    }
                    if (percent > 59 && percent <= 66) {
                        img8.style.opacity = 1
                        img8.style.transition = "1.5s all";
                        img9.style.opacity = 0;
                        img10.style.opacity = 0;
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        changeStep(8)
                        return;
                    }
                    if (percent > 66) {
                        img8.style.opacity = 1
                    }
                    if (percent > 66 && percent <= 73) {
                        img9.style.opacity = 1
                        img9.style.transition = "1.5s all";
                        img10.style.opacity = 0;
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        changeStep(9)
                        return;
                    }
                    if (percent > 73) {
                        img9.style.opacity = 1
                    }
                    if (percent > 73 && percent <= 80) {
                        img10.style.opacity = 1
                        img10.style.transition = "1.5s all";
                        img11.style.opacity = 0;
                        img12.style.opacity = 0;
                        changeStep(10)
                        return;
                    }
                    if (percent > 80) {
                        img10.style.opacity = 1
                    }
                    if (percent > 80 && percent <= 87) {
                        img11.style.opacity = 1
                        img11.style.transition = "1.5s all";
                        img12.style.opacity = 0;
                        changeStep(11)
                        return;
                    }
                    if (percent > 87) {
                        img11.style.opacity = 1
                    }
                    if (percent > 87 && percent <= 95) {
                        img12.style.opacity = 1
                        img12.style.transition = "1.5s all";
                        changeStep(12)
                        return;
                    }
                    if (percent > 95) {
                        img12.style.opacity = 1
                    }
                }
            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])

    return (
        <div className={classes.root} id={"insight-map-tree"}>
            <div id={'insight-map-tree-content'} className={classes.content}>
                <img
                    id={'insight-map-tree-img-root'}
                    className={classes.imgRoot}
                    src={'/images/maps/part6-2.png'} alt={"innertours"}/>
                <div id={'textBigAbsolute'} className={classes.textPrimary}>
                    {
                        TextSteps.map((val, index) => {
                            if (step !== val.id)
                                return null
                            return (
                                <DivTextMemo id={`textPrimary${val.id}`} value={val} key={index}/>
                            )
                        })
                    }
                </div>

                {
                    TextSteps.map((val, index) => {
                        return (<div key={index} id={`insight-map-tree-img-${index + 1}`}
                                     className={`${classes.imgDiv} ${classes[`img${index + 1}`]}`}
                        >
                            <div className={classes.textSubTop} style={{color: val.color}}>{val.id}</div>
                            <div className={classes.textSubMid}>{val.engText}</div>
                            <div className={classes.textSubBottom}>{val.secondary}</div>
                        </div>)
                    })
                }
            </div>
        </div>

    );
}

export default InsightMapTree;

const useStyles = makeStyles(theme => ({
    root: {
        height: "800vh",
        position: 'relative',
        // backgroundColor: "#f9f9f9",
        marginTop: 24,
        zIndex: 9
    },
    textSubTop: {
        fontSize: 30,
        [theme.breakpoints.down('md')]: {
            fontSize: 28,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 20
        },
    },
    textSubMid: {
        fontSize: 15,
        fontFamily: "SHSNormal, sans-serif",
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: 15,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 8
        },
    },
    textSubBottom: {
        fontSize: 15,
        fontFamily: "SHSNormal, sans-serif",
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: 15,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 8
        },
    },
    textPrimary: {
        position: "absolute",
        top: "5%",
        left: "15%",
        right: 0,
        transform: "translate(-15%, -5%)",
        opacity: 1,
        [theme.breakpoints.down('sm')]: {
            left: "20%",
            transform: "translate(-20%)",
        },
        // [theme.breakpoints.down('sm')]: {
        //     left: "10%",
        //     transform: "translate(-10%)",
        // },
    },
    imgRoot: {
        height: "100%",
        margin: "auto",
        // position: "absolute",
        // top: 0,
        // left: 0,
        // right: 0,
        opacity: 1,
        [theme.breakpoints.down('xs')]: {
            transform: "scale(0.8)"
        },
        // transform: "translateX(70vw)",
    },
    imgDiv: {
        width: 100,
        height: "auto",
        margin: "auto",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        transform: "translateX(70vw)",
        opacity: 1,
        lineHeight: 1.3,
        [theme.breakpoints.down('lg')]: {
            width: 80,
        },
        [theme.breakpoints.down('md')]: {
            width: 80,
        },
        [theme.breakpoints.down('sm')]: {
            width: 80,
        },
        [theme.breakpoints.down('xs')]: {
            width: 55,
        },
    },
    img1: {
        top: 0,
        opacity: 0,
        transform: 'translate(10vw)',
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(14vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(20vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(25vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(30vw)',
        },

    },
    img2: {
        top: "10%",
        opacity: 0,
        transform: 'translate(14vw)',
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(20vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(25vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(35vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(45vw)',
        },
    },
    img3: {
        top: "20%",
        opacity: 0,
        transform: 'translate(14vw)',
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(20vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(25vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(35vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(45vw)',
        },
    },
    img4: {
        top: "30%",
        opacity: 0,
        transform: 'translate(10vw)',
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(14vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(20vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(25vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(30vw)',
        },
    },
    img5: {
        top: "30%",
        opacity: 0,
        transform: 'translate(-10vw)',
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(-14vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(-20vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(-25vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(-30vw)',
        },
    },
    img6: {
        top: "40%",
        height: 100,
        opacity: 0,
        transform: 'translate(-14vw)',
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(-20vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(-25vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(-35vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(-45vw)',
        },
    },
    img7: {
        top: "50%",
        opacity: 0,
        transform: 'translate(-14vw)',
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(-20vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(-25vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(-35vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(-45vw)',
        },
    },
    img8: {
        top: "60%",
        transform: 'translate(-10vw)',
        opacity: 0,
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(-14vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(-20vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(-25vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(-30vw)',
        },
    },
    img9: {
        top: "55%",
        height: 100,
        opacity: 0,
        transform: 'translate(10vw)',
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(14vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(20vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(25vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(30vw)',
        },
    },
    img10: {
        top: "70%",
        transform: 'translate(14vw)',
        opacity: 0,
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(20vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(25vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(35vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(45vw)',
        },
    },
    img11: {
        top: "80%",
        width: 120,
        height: 60,
        transform: 'translate(14vw)',
        opacity: 0,
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(20vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(25vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(35vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(45vw)',
        },
    },
    img12: {
        top: "90%",
        transform: 'translate(10vw)',
        opacity: 0,
        [theme.breakpoints.down('lg')]: {
            transform: 'translate(14vw)',
        },
        [theme.breakpoints.down('md')]: {
            transform: 'translate(20vw)',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'translate(25vw)',
        },
        [theme.breakpoints.down('xs')]: {
            transform: 'translate(30vw)',
        },
    },
    content: {
        position: "sticky",
        top: 0,
        bottom: 0,
        height: "100vh",
        overflow: 'hidden',
        zIndex: 2,
        marginTop: '-100vh'
    },
}))