import React, {useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {findIndex} from 'lodash'

function PlayBodyMovin({
                           path,
                           height = "300vh",
                           marginTop = null,
                           playFromStart = false,
                           elementStyle = {},
                           playText = [],
                           textLeft = []
                       }) {
    const classes = useStyles()
    const ref = useRef(null)
    useEffect(() => {
        if (!path || !document.getElementById(`container-${path}`))
            return;
        ref.current = window.bodymovin.loadAnimation({
            container: document.getElementById(path),
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: path,
        })
        const gotoFrame = () => {
            let total = document.getElementById(`container-${path}`)?.offsetHeight
            let start = document.getElementById(`container-${path}`)?.offsetTop
            let screenHeight = window.innerHeight
            const totalFrames = ref.current?.totalFrames
            if (marginTop) {
                start = start + screenHeight
                total = total - screenHeight
            }
            if (!totalFrames)
                return;
            if (window.scrollY < start && window.scrollY >= start - screenHeight / 2) {
                ref.current.goToAndStop(1, true)
            }
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight * 1.5

                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                // console.log(percent);
                const textContainer = document.getElementById(`text-${path}`)
                // if (percent > 90 && percent < 95) {
                //     const textcenter = document.getElementById('horse-text-center')
                //     textcenter.style.opacity = 1
                // } else {
                //     const textcenter = document.getElementById('horse-text-center')
                //     textcenter.style.opacity = 0
                // }
                if (playText.length) {
                    const itemIdx = findIndex(playText, item => (percent >= item?.visibility[0] * 100 && percent < item?.visibility[1] * 100))
                    if (itemIdx === -1) {
                        textContainer.innerHTML = ""
                        textContainer.style.transform = ""
                    } else {
                        const item = playText[itemIdx]
                        textContainer.innerHTML = item.text
                        if (item.transform) {
                            textContainer.style.transform = item.transform
                        } else {
                            textContainer.style.transform = ""
                        }
                    }

                } else {
                    textContainer.innerHTML = ""
                }
                const left = document.getElementById('text-left')
                if (textLeft.length) {
                    const itemIdx = findIndex(textLeft, item => (percent >= item?.visibility[0] * 100 && percent < item?.visibility[1] * 100))
                    if (itemIdx === -1) {
                        left.innerHTML = ""
                        left.style.transform = ""
                    } else {
                        const item = textLeft[itemIdx]
                        left.innerHTML = item.text
                        if (item.transform) {
                            left.style.transform = item.transform
                        } else {
                            left.style.transform = ""
                        }
                    }

                } else {
                    left.innerHTML = ""
                }
                const frame = percent * totalFrames / 100 - 1 <= 0 ? 1 : percent * totalFrames / 100 - 1
                ref.current.goToAndStop(parseInt(frame), true)
            }
        }
        ref.current.addEventListener("DOMLoaded", evt => {
            if (window.scrollY)
                gotoFrame()
            else ref.current.goToAndStop(1, true)
        })
        window.addEventListener('scroll', ev => {
            gotoFrame()
        })
        return () => {
            window.document.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
            if (ref.current) {
                ref.current.destroy()
            }
        }
    }, [path, ref.current?.isLoaded])

    if (!path)
        return;
    return (
        <div style={{height: height, marginTop}} id={`container-${path}`}>
            <div className={classes.content}>
                <div className={classes.bg1}/>
                <div className={classes.bg2}/>
                <div id={path} className={classes.bodymovin} style={elementStyle}/>
                <p id={`text-${path}`} className={classes.text}/>
                {/*<p className={classes.center} id={'horse-text-center'}>*/}
                {/*    <span className={classes.subTitle}>アンコンシャス・パラドクス</span><br/>*/}
                {/*    <span className={classes.title}>&nbsp;無自覚の自己矛盾</span><br/>*/}
                {/*</p>*/}
                <p id={'text-left'} className={classes.left}/>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    bodymovin: {
        "& svg": {
            maxHeight: "100vh",
            width: "100%",
            margin: "auto"
        },
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "100%"
    },
    content: {
        position: "sticky",
        top: 0,
        height: "100vh"
    },
    text: {
        position: "absolute",
        top: "50%",
        left: "60%",
        textAlign: "left",
        fontSize: "20pt",
        transform: "translate(0%,-70%)",
        [theme.breakpoints.down("md")]: {
            fontSize: "16pt",
            left: "65%",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "12pt",
            left: "65%",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14pt",
            top: "30%",
            left: "10%",
        },
    },
    center: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        transition: "all 0.5s"
    },
    title: {
        fontSize: "60pt",
        lineHeight: 1,
        margin: 0,
        [theme.breakpoints.down("md")]: {
            fontSize: "45pt"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "28pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "12pt"
        },
        background: "linear-gradient(to right, rgba(20, 136, 246, 1) 0%, 13.82022500038147%, rgba(101, 4, 243, 1) 27.64045000076294%, 63.82022500038147%, rgba(254, 183, 10, 1) 100%);",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        display: "inline-block"
    },
    subTitle: {
        fontSize: "38pt",
        lineHeight: 1,
        margin: 0,
        [theme.breakpoints.down("md")]: {
            fontSize: "28pt"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "17pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "7pt"
        },
        background: "linear-gradient(to right, rgba(20, 136, 246, 1) 0%, 13.82022500038147%, rgba(101, 4, 243, 1) 27.64045000076294%, 63.82022500038147%, rgba(254, 183, 10, 1) 100%);",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        display: "inline-block"
    },
    endText: {
        fontSize: "55pt",
        lineHeight: 1,
        margin: 0,
        color: "#fff",
        [theme.breakpoints.down("md")]: {
            fontSize: "40pt"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "30pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "15pt"
        },
        position: "absolute",
        top: "55%",
        left: "60%"
    },
    endText2: {
        fontSize: "55pt",
        lineHeight: 1,
        margin: 0,
        color: "#fff",
        [theme.breakpoints.down("md")]: {
            fontSize: "40pt"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "30pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "15pt"
        },
        position: "absolute",
        top: "70%",
        left: "40%"
    },
    endText3: {
        fontSize: "25pt",
        lineHeight: 1,
        margin: 0,
        color: "#fff",
        [theme.breakpoints.down("md")]: {
            fontSize: "20pt",
            left: "10%"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "17pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "7pt"
        },
        position: "absolute",
        top: "50%",
        left: "20%"
    },
    left: {
        position: "absolute",
        bottom: "25%",
        left: "30%",
        fontSize: "30px",
        transform: "translateY(100%)",
        [theme.breakpoints.down("lg")]: {
            bottom: "30%",
            left: "25%",
            fontSize: "25px",
        },
        [theme.breakpoints.down("md")]: {
            bottom: "30%",
            left: "25%",
            fontSize: "18px",
        },
        [theme.breakpoints.down("sm")]: {
            bottom: "40%",
            left: "20%",
            fontSize: "18px",
        },
        [theme.breakpoints.down("xs")]: {
            bottom: "18%",
            left: "10%",
        },
    },
    bg1: {
        position: "absolute",
        top: 0,
        background: "#5A5A5A",
        left: 0,
        bottom: "50%",
        right: 0,
        zIndex: 0
    },
    bg2: {
        position: "absolute",
        top: "50%",
        background: "#0E0E0E",
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 0
    }
}))
export default PlayBodyMovin;