import React, {useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core";
import {useMediaWidth} from "../../context/mediaQuery";

function TextTop(props) {
    const classes = useStyles()
    const width = useMediaWidth();
    const ref = useRef(-10)
    useEffect(() => {
        const interval = setInterval(() => {
            if (ref.current < 100) {
                ref.current = ref.current + 1;
                const percent = ref.current
                const el = document.getElementById('textVideo')
                if (!el) return;
                const text = el.querySelector("#scaleText")
                const textAwaken = el.querySelector("#textAwaken")
                const contentTop = el.querySelector("#contentTop")
                if (percent <= 10) {
                    text.style.transform = "scale(1)"
                    textAwaken.style.display = "flex"
                    contentTop.style.opacity = 0
                    return;
                }
                if (percent > 10 && percent <= 50) {
                    let scale = percent <= 0 ? "scale(1)" : `scale(${(0.15 * percent) - 0.5})`
                    text.style.transform = scale
                    textAwaken.style.display = "flex"
                    contentTop.style.opacity = 0
                    return;
                }
                if (percent > 50) {
                    text.style.display = "none"
                    textAwaken.style.display = "none"
                    contentTop.style.opacity = 1
                    return;
                }
            } else {
                clearInterval(interval)
            }
        }, 40)

    }, [])
    return (
        <section className={classes.root} id={"textVideo"}>
            <div className={classes.text} id={"textAwaken"}>
                <span id={"scaleText"}>Awaken<br/>the World</span>
            </div>
            <video
                className={classes.video}
                preload="auto" autoPlay loop muted
                playsInline={true}
            >
                <source src={`/videos/metavesity-${width}.mp4`} type='video/mp4'/>
            </video>
            <div className={classes.content} id={"contentTop"}>
                <p>人類の可能性が目を覚ます。</p>
            </div>
        </section>
    );
}

export default TextTop;
const useStyles = makeStyles(theme => ({
    root: {
        // background: "#222",
        position: "relative",
        height: "100vh",
    },
    text: {
        // paddingTop: 50,
        fontSize: "150pt",
        fontWeight: 900,
        // WebkitTextStrokeWidth: "4px",
        WebkitTextStrokeColor: "#fff",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "#000",
        color: "#fff",
        mixBlendMode: "multiply",
        "& span": {
            lineHeight: 0.9,
            transform: "scale(1)"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "40pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "30pt"
        }
    },
    video: {
        width: "100%",
        height: "100vh",
        objectFit: "cover",
        backgroundColor: "#000",
        [theme.breakpoints.down("sm")]: {
            objectFit: "contain",
        },
    },
    content: {
        color: "#fff",
        position: "absolute",
        top: "50%",
        left: 0,
        right: 0,
        transform: "translateY(-50%)",
        fontSize: "70pt",
        opacity: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: "30pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "20pt"
        }
    }

}))