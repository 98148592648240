import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography, useTheme} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        height: "200vh",
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            height: "auto",
        },
    },
    root: {
        background: "linear-gradient(180deg, rgba(249,249,249,1) 0%, rgba(223,223,223,1) 100%, rgba(227,227,227,1) 100%)",
        width: "100%",
        height: "100vh",
        position: 'sticky',
        top: 0,
        // overflow: "hidden",
        [theme.breakpoints.down('sm')]: {
            height: "unset",
            position: "relative"
        },
        // [theme.breakpoints.down('md')]: {
        //     height: "auto",
        // },
    },
    imageDiv: {
        width: "80%",
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 'auto'
    },
    imageDivSub: {
        width: "85%",
        maxWidth: 500,
        margin: "0 auto 30px auto",
        // display: "inline-block",
        "& img": {
            width: "100%",
            height: "100%",
        }
    },
    content: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: 0,
        right: 0,
        [theme.breakpoints.down('sm')]: {
            position: "static",
            transform: "unset",
        },
    },
    imageDivTypo: {
        fontSize: "35pt",
        fontWeight: "bold",
        textAlign: "center",
        marginTop: 20,
        [theme.breakpoints.down('md')]: {
            fontSize: '30pt',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '20pt',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '15pt',
        },
    },
    textTop: {
        fontSize: "55pt",
        textAlign: "center",
        marginBottom: 70,
        // position: "absolute",
        // top: 0,
        // transform: "translateY(40vh)",
        width: "100%",
        "& p": {
            margin: 0
        },
        "& span": {
            display: "block",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '35pt',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '30pt',
            margin: "30px 0",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '18pt',
        },
    },
    imgDiv: {
        position: "relative",
        paddingTop: "65%",
        "& img": {
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            objectFit: 'contain'
        }
    },
    // content1: {
    //     top: "55%",
    //     transform: "translate(-50%,-50%)",
    //     position: "absolute",
    //     [theme.breakpoints.down('xs')]: {
    //         transform: "translate(-100%,-110%)",
    //     },
    // },
    // content2: {
    //     top: "55%",
    //     transform: "translate(50%,-50%)",
    //     position: "absolute",
    //     [theme.breakpoints.down('xs')]: {
    //         transform: "translate(100%,0%)",
    //     },
    // },
    // container: {
    //     position: "absolute",
    //     top: "60%",
    //     transform: "translateY(-50%)"
    // }
}));

function UnifyTheInside(props) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.container}>
            <section className={classes.root} id={'unify'}>
                <div className={classes.content}>
                    <div className={classes.textTop}>
                        <span>リアルでも、オンラインでも</span>
                        <span>Unify The Insight</span>
                    </div>
                    <Grid container>
                        <Grid item sm={6} xs={12}>
                            <div id={"unify1"} className={`${classes.imageDivSub}`}>
                                <div className={classes.imgDiv}>
                                    <img src={'/images/Asset1.png'}/>
                                </div>
                                <Typography className={classes.imageDivTypo}>Insight Mapキット</Typography>
                            </div>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <div className={`${classes.imageDivSub}`}>
                                <div className={classes.imgDiv}>
                                    <img src={'/images/Asset2.png'}/>
                                </div>
                                <Typography className={classes.imageDivTypo}>WEBアプリケーション</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </div>
    );
}

export default UnifyTheInside;