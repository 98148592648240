import React, {useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";

function Part9(props) {
    const classes = useStyles()
    const ref = useRef(null)
    useEffect(() => {
        const el = document.getElementById('container-part9')
        if (!el) return;
        ref.current = window.bodymovin.loadAnimation({
            container: document.getElementById("part9"),
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: '/data/part9.json',
        })
        const gotoFrame = () => {
            const el = document.getElementById("container-part9")
            if (!el) return;
            const total = document.getElementById(`container-part9`)?.offsetHeight
            const start = document.getElementById(`container-part9`)?.offsetTop
            const screenHeight = window.innerHeight
            const totalFrames = ref.current?.totalFrames
            if (!totalFrames)
                return;
            if (window.scrollY < start && window.scrollY >= start - screenHeight / 2) {
                ref.current.goToAndStop(1, true)
            }
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight * 1.5
                if (scrollItem > scrollValid) {
                    return;
                }
                const endmessage = document.getElementById("part9-end")
                const slide1 = document.getElementById("slide1")
                const slide2 = document.getElementById("slide2")
                const slide3 = document.getElementById("slide3")
                const slide4 = document.getElementById("slide4")
                const slide5 = document.getElementById("slide5")
                const slide6 = document.getElementById("slide6")
                const bg = document.getElementById("part9-bg")
                const cityOut = document.getElementById('city-out')
                const percent = Math.round(scrollItem / scrollValid * 100)
                const frame = (percent - 10) * totalFrames / 100 - 1

                if (percent > 0 && percent <= 15) {
                    let opacity = percent <= 0 ? 1 : -(percent * 0.07) + 1
                    slide1.style.opacity = opacity
                    slide2.style.opacity = 0
                    slide3.style.opacity = 0
                    slide4.style.opacity = 0
                    slide5.style.opacity = 0
                    slide6.style.opacity = 0
                    bg.style.opacity = 0
                    endmessage.style.opacity = 0
                    // cityOut.style.marginTop = 0
                    ref.current.goToAndStop(parseInt(1), true)
                    return;
                }
                if (percent > 15 && percent <= 30) {
                    let opacity = (percent * 0.05) - 0.6
                    slide1.style.opacity = 0
                    slide2.style.opacity = opacity
                    slide3.style.opacity = 0
                    slide4.style.opacity = 0
                    slide5.style.opacity = 0
                    slide6.style.opacity = 0
                    bg.style.opacity = 0
                    // cityOut.style.marginTop = 0
                    endmessage.style.opacity = 0
                    ref.current.goToAndStop(parseInt(1), true)
                    return;
                }
                if (percent > 30 && percent <= 45) {
                    let opacity = (percent * 0.08) - 2.2
                    slide1.style.opacity = 0
                    slide2.style.opacity = 0
                    slide3.style.opacity = opacity
                    slide4.style.opacity = 0
                    slide5.style.opacity = 0
                    slide6.style.opacity = 0
                    bg.style.opacity = 0
                    // cityOut.style.marginTop = 0
                    endmessage.style.opacity = 0
                    ref.current.goToAndStop(parseInt(frame), true)
                    return;
                }
                if (percent > 45 && percent <= 60) {
                    let opacity = (percent * 0.08) - 3.4
                    slide1.style.opacity = 0
                    slide2.style.opacity = 0
                    slide3.style.opacity = 0
                    slide4.style.opacity = opacity
                    slide5.style.opacity = 0
                    slide6.style.opacity = 0
                    bg.style.opacity = 0
                    // cityOut.style.marginTop = 0
                    endmessage.style.opacity = 0
                    ref.current.goToAndStop(parseInt(frame), true)
                    return;
                }
                if (percent > 60 && percent <= 75) {
                    let opacity = (percent * 0.08) - 4.6
                    slide1.style.opacity = 0
                    slide2.style.opacity = 0
                    slide3.style.opacity = 0
                    slide4.style.opacity = 0
                    slide5.style.opacity = opacity
                    slide6.style.opacity = 0
                    bg.style.opacity = 0
                    // cityOut.style.marginTop = 0
                    endmessage.style.opacity = 0
                    ref.current.goToAndStop(parseInt(frame), true)
                    return;
                }
                if (percent > 75 && percent <= 90) {
                    let opacity = (percent * 0.08) - 5.8
                    slide1.style.opacity = 0
                    slide2.style.opacity = 0
                    slide3.style.opacity = 0
                    slide4.style.opacity = 0
                    slide5.style.opacity = 0
                    slide6.style.opacity = opacity
                    bg.style.opacity = 0
                    // cityOut.style.marginTop = 0
                    endmessage.style.opacity = 0
                    ref.current.goToAndStop(parseInt(frame), true)
                    return;
                }
                if (percent > 90) {
                    slide1.style.opacity = 0
                    slide2.style.opacity = 0
                    slide3.style.opacity = 0
                    slide4.style.opacity = 0
                    slide5.style.opacity = 0
                    slide6.style.opacity = 0
                    // cityOut.style.marginTop = 0
                    endmessage.style.opacity = 1
                    bg.style.opacity = (percent - 90) / 5
                    ref.current.goToAndStop(parseInt(frame), true)
                    return;
                }
                if (percent > 95) {
                    slide1.style.opacity = 0
                    slide2.style.opacity = 0
                    slide3.style.opacity = 0
                    slide4.style.opacity = 0
                    slide5.style.opacity = 0
                    slide6.style.opacity = 0
                    // cityOut.style.marginTop = "-200vh"
                    bg.style.opacity = 1 - (percent - 95) / 5
                    endmessage.style.opacity = 1
                    ref.current.goToAndStop(parseInt(frame), true)
                    return;
                }

            }
        }
        ref.current.addEventListener("DOMLoaded", evt => {
            if (window.scrollY)
                gotoFrame()
            else ref.current.goToAndStop(1, true)
        })
        window.addEventListener('scroll', ev => {
            gotoFrame()
        })
        return () => {
            if (ref.current) {
                ref.current.destroy()
            }
            window.removeEventListener('scroll', ev => {

            })
        }

    }, [])
    return (
        <div className={classes.root} id={'container-part9'}>
            <div className={classes.content}>
                <Grid container>
                    <Grid item sm={6} xs={12}>
                        <div className={classes.heightDefault}/>
                    </Grid>
                    <div className={`${classes.contentSlide} ${classes.slide1}`} id={"slide1"}>
                        <div className={classes.inlineBlock}>
                            <h1 className={classes.title}>The World Needs</h1>
                            <span className={classes.title2}>Awakeners.</span>
                        </div>
                        <p className={classes.textContent}>&nbsp;&nbsp;今、世界中の人類は葛藤を抱えている。</p>
                        <p className={classes.textContent}>&nbsp;&nbsp;だから今、人々の<span
                            className='gradientText'>無自覚の自己矛盾</span></p>
                        <p className={classes.textContent}>&nbsp;&nbsp;を可視化し、紐解き、統合し、</p>
                        <p className={classes.textContent}>「本来の自己の目覚めを支援できる人」</p>
                        <p className={classes.textContent}>&nbsp;&nbsp;が世界には必要なのだ。</p>
                    </div>
                    <div className={`${classes.contentSlide}`} id={"slide2"}>
                        <div className={classes.titleSpecial}>Insight Map</div>
                        <div className={classes.titleSpecial}>ファシリテータコース</div>
                    </div>
                    <div className={`${classes.contentSlide}`} id={"slide3"}>
                        <div>
                            <h1 className={classes.title}>できること①</h1>
                        </div>
                        <h2 className={classes.subTitle}>For 1on1セッション</h2>
                        <p className={classes.textContent}>人が<span className='greenText'>真価を発揮できない原因</span>は、<br/>
                            <span className='gradientText'>無自覚の自己矛盾</span>にあります。</p>
                        <p className={classes.textContent}>ライトにやれば90分、ディープにやると180分。<br/>
                            たったこれだけの時間で、人の人生レベルの<br/>
                            葛藤をひも解くことができます。<br/></p>
                        <p className={classes.textContent}>しかも誰かの葛藤をひも解くたびに、<br/>
                            人の深層心理をどんどん深く理解できてしまい、<br/>
                            <span className='yellowText'>人間へのインサイト（洞察）</span>が高まっていきます。<br/>
                        </p>
                    </div>
                    <div className={`${classes.contentSlide}`} id={"slide4"}>
                        <div>
                            <h1 className={classes.title}>できること②</h1>
                        </div>
                        <h2 className={classes.subTitle}>For チームビルディング</h2>
                        <p className={classes.textContent}>チームの不協和音は<span
                            className='greenText'>相互理解の不足</span>によって起き<br/>
                            それは、一人ひとりの<span className='greenText'>自己理解不足に</span>よって起きます。</p>
                        <p className={classes.textContent}>そして、人は腹を割って話し合ったつもりが、<br/>
                            双方の<span className='gradientText'>無自覚の自己矛盾</span>によって<br/>
                            お互いに、本心と真逆のことを話してしまうことががあります。
                        </p>
                        <p className={classes.textContent}>１枚のインサイトマップ共同作業で作ることで、<br/>
                            お互いの<span className='yellowText'>「深層心理が可視化された地図」</span>に基づいて、<br/>
                            人と人が対話することが可能になります。</p>
                    </div>
                    <div className={`${classes.contentSlide}`} id={"slide5"}>
                        <div>
                            <h1 className={classes.title}>できること③</h1>
                        </div>
                        <h2 className={classes.subTitle}>For ワークショップ</h2>
                        <p className={classes.textContent}>例えばセールス研修では、顧客の立場になりきって<br/>
                            <span className='greenText'>「この商品を買うか？買わないか？」</span><br/>
                            の葛藤をインサイトマップでひも解いてみます。</p>
                        <p className={classes.textContent}>すると顧客の中に眠る<br/>
                            <span className='gradientText'>無自覚の自己矛盾</span><br/>
                            が明確になれば、<br/><span className='yellowText'>顧客の深層心理を理解</span>でき、<br/>
                            深い洞察を伴った提案ができるようになります。
                        </p>
                    </div>
                    <div className={`${classes.contentSlide}`} id={"slide6"}>
                        <div>
                            <h1 className={classes.title}>できること④</h1>
                        </div>
                        <h2 className={classes.subTitle}>For 自己探求</h2>
                        <p className={classes.textContent}><span className='greenText'>表層的な自己理解</span>では、<br/>
                            自己の真価を発揮することはできません。</p>
                        <p className={classes.textContent}>自分の中にある、人間関係や、仕事、お金など<br/>
                            に対する葛藤をインサイトマップに書くことで<br/>
                            <span className='gradientText'>無自覚の自己矛盾</span><br/>
                            を特定し、ひも解くことができます。<br/>
                            そして、内面を俯瞰し、統合された道が見えた時、<br/>
                            <span className='yellowText'>「眠れる真の才能」</span>が開き始め ます。
                        </p>
                    </div>
                    <Grid item sm={6} xs={12}>
                        <div id={"part9"}/>
                    </Grid>
                </Grid>
                <div className={classes.endMessage} id={'part9-end'}>
                    <span>Unify The Insight</span>
                </div>
                <div className={classes.blackBg} id={'part9-bg'}/>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: "700vh",
        backgroundColor: "#69d3eb",
        textAlign: 'left',
        "& svg": {
            maxHeight: "100vh"
        }
    },
    content: {
        height: "100vh",
        position: 'sticky',
        top: 0
    },
    inlineBlock: {
        display: "inline-block",
    },
    title: {
        fontSize: "80pt",
        lineHeight: 1,
        margin: 0,
        textAlign: "left",
        display: "inline-block",
        [theme.breakpoints.down('md')]: {
            fontSize: "35pt",
            lineHeight: 1.5
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "30pt",
            lineHeight: 1.5
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "20pt",
            lineHeight: 1.5
        }
    },
    title2: {
        fontSize: "80pt",
        lineHeight: 1,
        margin: 0,
        textAlign: "right",
        display: "block",
        [theme.breakpoints.down('md')]: {
            fontSize: "35pt",
            lineHeight: 1.5
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "30pt",
            lineHeight: 1.5
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "20pt",
            lineHeight: 1.5
        }
    },
    titleSpecial: {
        margin: "40px 0",
        textAlign: "center",
        fontSize: "80pt",
        lineHeight: 1,
        display: "block",
        [theme.breakpoints.down('md')]: {
            fontSize: "35pt",
            lineHeight: 1.5
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "30pt",
            lineHeight: 1.5
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "20pt",
            lineHeight: 1.5
        }
    },
    subTitle: {
        fontSize: "40pt",
        margin: 0,
        lineHeight: 1,
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            fontSize: "20pt",
            lineHeight: 1.5
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "15pt",
            lineHeight: 1.5
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "10pt",
            lineHeight: 1.5
        }
    },
    textContainer: {
        position: 'absolute',
        top: 50,
        left: 100,
        zIndex: 2,
        maxWidth: "calc(100% - 100px)",
        [theme.breakpoints.down('xs')]: {
            position: "relative",
            left: 20,
            maxWidth: "calc(100% - 20px)",
        }
    },
    textContent: {
        fontSize: "25pt",
        [theme.breakpoints.down('md')]: {
            fontSize: "20pt",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "15pt",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "10pt",
        }
    },
    yellowText: {
        color: "#FC9B15"
    },
    greenText: {
        color: "#472AFC"
    },
    gradientText: {
        background: "linear-gradient(to right, #0000ff 20%, #ff7600 40%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        display: "inline-block"
    },
    endMessage: {
        position: "absolute",
        top: '50%',
        left: '50%',
        color: "#fff",
        transform: "translate(-50%,-50%)",
        fontSize: "50pt",
        opacity: 0,
        [theme.breakpoints.down('md')]: {
            fontSize: "40pt",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "25pt",
        },
        [theme.breakpoints.down('xs')]: {
            top: "25%",
            width: "100%",
            textAlign: "center"
        }
    },
    subContent: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "100px",
        textAlign: "center",
        zIndex: 2,
        maxWidth: "calc(100% - 100px)",
        [theme.breakpoints.down('xs')]: {
            position: "relative",
            left: 0,
            maxWidth: "unset",
        }
    },
    contentSlide: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "50px",
        opacity: 0,
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            top: "50px",
            transform: "unset",
            width: "calc(100% - 50px)",

        }
    },
    slide1: {
        opacity: 1,
    },
    heightDefault: {
        [theme.breakpoints.down('xs')]: {
            height: "40vh"
        }
    },
    blackBg: {
        background: "#000",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 3,
        opacity: 0
    }
}))

export default Part9;