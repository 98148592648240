import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper, Typography} from "@material-ui/core";

const dataText = [
    {
        id: 1,
        text1: "1ヶ月目",
        text2: "基礎編"
    },
    {
        id: 2,
        text1: "2ヶ月目",
        text2: "技術編"
    },
    {
        id: 3,
        text1: "3ヶ月目",
        text2: "応用編"
    },

]
const dataText2 = [
    {
        id: 1,
        text1: "毎週土曜日",
        text2: "9:00 - 17:00"
    }
]

const dayText = [
    {
        id: 1,
        primaryText: "DAY1",
        secondaryText: "09/04 ( 土 )"
    },
    {
        id: 2,
        primaryText: "DAY2",
        secondaryText: "09/18 ( 土 )"
    },
    {
        id: 3,
        primaryText: "DAY3",
        secondaryText: "09/25 ( 土 )"
    },
    {
        id: 4,
        primaryText: "DAY4",
        secondaryText: "10/02 ( 土 )"
    },
    {
        id: 5,
        primaryText: "DAY5",
        secondaryText: "10/09 ( 土 )"
    },
    {
        id: 6,
        primaryText: "DAY6",
        secondaryText: "10/16 ( 土 )"
    },
    {
        id: 7,
        primaryText: "DAY7",
        secondaryText: "10/23 ( 土 )"
    },
    {
        id: 8,
        primaryText: "DAY8",
        secondaryText: "10/30 ( 土 )"
    },
    {
        id: 9,
        primaryText: "DAY9",
        secondaryText: "11/06 ( 土 )"
    },
    {
        id: 10,
        primaryText: "DAY10",
        secondaryText: "11/13 ( 土 )"
    },
    {
        id: 11,
        primaryText: "DAY11",
        secondaryText: "11/20 ( 土 )"
    },
    {
        id: 12,
        primaryText: "DAY12",
        secondaryText: "11/27 ( 土 )"
    }
]

function Part10(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.textTop}>
                    ３ヶ月集中 プログラム <br/>
                    オンラインLive講座12日間
                </div>
                <Grid container className={classes.gridContainer} justifyContent="center">
                    <Grid item md={8} sm={12} xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container className={classes.paperLeft} justifyContent="center">
                                {dataText.map((value, index) => (
                                    <Grid key={index} item className={classes.itemPaperLeft}>
                                        <div>{value.text1}<br/>{value.text2}</div>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container style={{padding: 10}} spacing={1}>
                                <Grid item xs={4} style={{
                                    borderRadius: 10,
                                    position: "relative"
                                }}>
                                    <img style={{width: "100%", height: "100%"}} alt={'12day'}
                                         src={"/images/facilitator/step1.svg"}/>
                                    <div style={{
                                        position: "absolute",
                                        top: "25%",
                                        width: "100%",
                                        height: 5,
                                        zIndex: 2,
                                        background: "#fff"
                                    }}/>
                                    <div style={{
                                        position: "absolute",
                                        top: "50%",
                                        width: "100%",
                                        height: 5,
                                        zIndex: 2,
                                        background: "#fff"
                                    }}/>
                                    <div style={{
                                        position: "absolute",
                                        top: "75%",
                                        width: "100%",
                                        height: 5,
                                        zIndex: 2,
                                        background: "#fff"
                                    }}/>
                                    <div style={{position: "absolute", top: "10%", zIndex: 2, left: 15}}
                                         className={classes.day}>
                                        Day 1
                                    </div>
                                    <div style={{position: "absolute", top: "35%", zIndex: 2, right: 15}}
                                         className={classes.day}>
                                        Day 2
                                    </div>
                                    <div style={{position: "absolute", top: "60%", zIndex: 2, left: 15}}
                                         className={classes.day}>
                                        Day 3
                                    </div>
                                    <div style={{position: "absolute", top: "85%", zIndex: 2, right: 15}}
                                         className={classes.day}>
                                        Day 4
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={{
                                        height: "calc(50% - 5px)",
                                        backgroundColor: "#F1F1F1",
                                        width: "100%",
                                        borderRadius: "10px"
                                    }}>
                                        <div className={classes.divDayMore}>
                                            <Typography className={classes.day}>Day 5,6</Typography>
                                            <img alt={''} src={'/images/facilitator/day56.png'}
                                                 className={classes.box1Img}/>
                                            <Typography className={classes.day}>一致的な傾聴技術</Typography>
                                        </div>
                                    </div>
                                    <div style={{
                                        height: "calc(50% - 5px)",
                                        backgroundColor: "#F1F1F1",
                                        width: "100%",
                                        borderRadius: "10px",
                                        marginTop: 5
                                    }}>
                                        <div className={classes.divDayMore}>
                                            <Typography className={classes.day}>Day 7,8</Typography>
                                            <img alt={''} src={'/images/facilitator/day78.svg'}
                                                 className={classes.box1Img}/>
                                            <Typography className={classes.day}>深層心理の解析</Typography>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={{
                                        height: "calc(50% - 5px)",
                                        backgroundColor: "#F1F1F1",
                                        width: "100%",
                                        borderRadius: "10px"
                                    }}>
                                        <div className={classes.divDayMore}>
                                            <Typography className={classes.day}>Day 9,10</Typography>
                                            <img alt={''} src={'/images/facilitator/day9.svg'}
                                                 className={classes.box1Img}/>
                                            <Typography className={classes.day}>グループワーク手法</Typography>
                                        </div>
                                    </div>
                                    <div style={{
                                        height: "calc(50% - 5px)",
                                        backgroundColor: "#F1F1F1",
                                        width: "100%",
                                        borderRadius: "10px",
                                        marginTop: 5
                                    }}>
                                        <div className={classes.divDayMore}>
                                            <Typography className={classes.day}>Day 11,12</Typography>
                                            <img alt={''} src={'/images/facilitator/day11.svg'}
                                                 className={classes.box1Img}/>
                                            <Typography className={classes.day}>独自プログラムの設計</Typography>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} className={classes.smTop}>
                        <Paper className={classes.paper}>
                            <Grid container className={classes.paperLeft} justifyContent="center">
                                {dataText2.map((value, index) => (
                                    <Grid key={index} item className={classes.itemPaperRight}>
                                        <div>{value.text1}<br/>{value.text2}</div>
                                    </Grid>
                                ))}
                            </Grid>
                            <div style={{width: "calc(100% - 20px)", height: "100%", padding: 10}}>
                                {
                                    dayText.map((day, index) => {
                                        return (
                                            <Grid key={"day" + index} container
                                                  className={`${classes.dayGrid} ${index === 0 ? classes.borderTop : index === 11 ? classes.borderBottom : null}`}>
                                                <Grid item xs={6} className={classes.dayItem}>
                                                    <Typography className={classes.day}> {day.primaryText}</Typography>
                                                </Grid>
                                                <Grid item xs={6} className={classes.dayItem}>
                                                    <Typography
                                                        className={classes.day}> {day.secondaryText}</Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                                <div
                                    className={`${classes.dayNotice} ${classes.flexCenter}`}>※参加できない日程があったとしても、講義の録画データを閲覧することが可能です。
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Part10;

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        height: "200vh",
        margin: "auto",
        background: "#F1F1F1",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
        },
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        height: "100vh",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
        },
    },
    smTop: {
        [theme.breakpoints.down("sm")]: {
            marginTop: 20
        },
    },
    divDayMore: {
        width: "100%", height: "100%", padding: 5
    },
    dayItem: {
        border: "2px solid #FFFFFF",
        padding: 9,
        [theme.breakpoints.down("lg")]: {
            padding: 3,
        },
        [theme.breakpoints.down("md")]: {
            padding: 3
        },
        [theme.breakpoints.down("sm")]: {
            padding: 3
        },
        [theme.breakpoints.down("xs")]: {
            padding: 3
        },
    },
    borderTop: {
        borderRadius: "13px 13px 0px 0px"
    },
    borderBottom: {
        borderRadius: "0px 0px 13px 13px"
    },
    dayGrid: {
        background: "#F1F1F1",
    },
    textTop: {
        fontSize: 30,
        lineHeight: "45px",
        background: "#FFF",
        width: "30%",
        margin: "auto",
        borderRadius: "0px 0px 26px 26px",
        [theme.breakpoints.down("md")]: {
            width: "50%",
            fontSize: "25px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "70%",
            fontSize: "20px",
            lineHeight: "45px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "18px",
            width: "90%",
            lineHeight: "40px",
        },
    },
    gridContainer: {
        flexGrow: 1,
        padding: "40px 10px",
        // padding: "47px 68px",
        [theme.breakpoints.down("md")]: {
            // padding: "47px 68px",
        },
        [theme.breakpoints.down("sm")]: {
            // padding: "30px 20px",
        },
        [theme.breakpoints.down("xs")]: {
            // padding: "30px 15px",
        },
    },
    gridItem: {
        background: "#FFF",

    },
    paper: {
        margin: 10,
        borderRadius: 20,
        boxShadow: 'unset',
        // margin: "0 auto 30px auto",
        height: "100%"
    },
    paperLeft: {
        background: "#2B3E60",
        borderRadius: 20,
        width: "100%",
        height: 90,
        margin: "auto",
        [theme.breakpoints.down("md")]: {
            height: 90,
        },
        [theme.breakpoints.down("sm")]: {
            height: 70,
        },
        [theme.breakpoints.down("xs")]: {
            height: 50,
        },
    },
    paperLeftBottom: {},
    itemPaperLeft: {
        width: "33%",
        fontSize: 25,
        color: "#FFF",
        padding: 2,
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
            fontSize: "18px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    itemPaperRight: {
        width: "100%",
        fontSize: 25,
        color: "#FFF",
        padding: 2,
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
            fontSize: "18px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    itemSub: {
        padding: 10,
        width: "100%",
        "& img": {
            width: "100%"
        }
    },
    itemSubGrid: {},
    itemSubGridItem: {
        width: "100%",
        "& img": {
            width: "100%"
        }
    },
    day: {
        fontSize: "18px",
        fontWeight: 700,
        height: "15%",
        [theme.breakpoints.down("md")]: {
            fontSize: "10px",
            height: "13%",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
            height: "12%",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
            height: "10%",
        },
    },
    dayNotice: {
        fontSize: "18px",
        fontWeight: 700,
        width: "90%",
        margin: "auto",
        minHeight: "50px",
        [theme.breakpoints.down("md")]: {
            fontSize: "10px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px"
        },
    },
    box1Img: {
        width: "40%",
        margin: "5px auto",
        height: "60%",
        objectFit: "contain",
        [theme.breakpoints.down("md")]: {
            width: "30%",
            height: "50%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "40%",
            height: "50%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "30%",
            height: "40%",
        },
    },
}))