import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core";

function Horse(props) {
    const classes = useStyles()
    useEffect(() => {

        window.addEventListener('scroll', ev => {
            const el = document.getElementById('horse-scroll')
            if (!el) return;
            const total = el?.offsetHeight
            const start = el?.offsetTop
            const screenHeight = window.innerHeight
            if (window.scrollY >= start) {
                const scrollItem = window.scrollY - start
                const scrollValid = total - screenHeight
                if (scrollItem > scrollValid) {
                    return;
                }
                const percent = Math.round(scrollItem / scrollValid * 100)
                const endText = el.querySelector("h3")
                const content = document.getElementById("horse-scroll-bg2")
                if (percent >= 0 && percent <= 50) {
                    endText.style.opacity = 0
                    content.style.zIndex = 0
                    return;
                }
                if (percent > 50) {
                    content.style.zIndex = 0
                    content.style.zIndex = 2
                    const opacity = (percent - 50) * 5 / 100
                    endText.style.opacity = opacity >= 1 ? 1 : opacity
                    return;
                }
            }
        })
        return () => {
            window.removeEventListener("scroll", ev => {
                console.log('unmount')
            })
        }
    }, [])

    return (
        <div className={classes.root} id={"horse-scroll"}>
            <div className={classes.content}>
                <div className={classes.bg1}/>
                <div id={'horse-scroll-bg2'} className={classes.bg2}/>
                <div className={classes.text}>
                    <h1 className={classes.subTitle}>
                        <p className={`${classes.smallGradient} ${classes.line}`}>
                            <span className={classes.letterSpace}>アンコンシャル・パラドクス</span>
                        </p>
                    </h1>
                    <h1 className={classes.subTitle}>
                        <p className={`${classes.bigGradient} ${classes.line}`}>
                                <span>
                                    無自覚の自己矛盾
                                </span>
                        </p>
                    </h1>
                    <h1 className={classes.title}>
                        <p className={`${classes.whiteSmall} ${classes.line}`}>
                                <span>
                                    が生まれる原因
                                </span>
                        </p>
                    </h1>
                    <h3 className={classes.endText}>
                        <p className={`${classes.whiteBig} ${classes.line}`}>
                                <span>
                                    馬車馬のブリンカー
                                </span>
                        </p>
                    </h3>
                </div>
            </div>
        </div>

    );
}

export default Horse;
const useStyles = makeStyles(theme => ({
    root: {
        height: "200vh",
        position: 'relative',
        backgroundColor: "#f9f9f9",
        zIndex: 2
    },
    letterSpace: {
        letterSpacing: 6,
        [theme.breakpoints.down("xs")]: {
            letterSpacing: 3,
        },
    },
    text: {
        position: "absolute",
        top: "50%",
        left: "15%",
        fontWeight: 700,
        textAlign: 'left',
        zIndex: 3,
        transform: "translateY(-50%)",
        // width: "100%",
        [theme.breakpoints.down("sm")]: {
            // marginLeft: 20,
            left: "10px",
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0
        },
    },
    title: {
        fontSize: "50pt",
        lineHeight: 1,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: "39pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "30pt"
        }
    },
    subTitle: {
        fontSize: "32pt",
        lineHeight: 1,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: "21pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "19pt"
        }
    },
    label: {
        fontSize: "20pt",
        lineHeight: 1,
        transform: "translateY(100vh)",
        opacity: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: "25pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14pt"
        }
    },
    endText: {
        fontSize: "50pt",
        lineHeight: 1,
        opacity: 0,
        position: "absolute",
        top: "50%",
        transform: "translateY(50%)",
        [theme.breakpoints.down("sm")]: {
            fontSize: "39pt"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "30pt"
        }
    },
    imgContainer: {
        width: "50%",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        // [theme.breakpoints.down("sm")]: {
        //     width: "70%"
        // },
        // [theme.breakpoints.down("xs")]: {
        //     width: "100%"
        // }
    },
    imgFlex: {
        display: "flex",
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    img: {
        // width: "100%",
        margin: "auto",
        transform: "translateY(100vh)"
    },
    img2: {
        left: 15,
        position: 'absolute',
        opacity: 0,
        margin: "auto",
        top: "7%",
        width: "10%",
        [theme.breakpoints.down("md")]: {
            left: 0,
        },
        // [theme.breakpoints.down("sm")]: {
        //     left: 150,
        // },
        // [theme.breakpoints.down("xs")]: {
        //     left: 180,
        // },
    },
    content: {
        height: "100vh",
        position: "sticky",
        top: 0,
        backgroundImage: "url(/images/bgHorse.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    bg1: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: "url(/images/bgHorse.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: 1
    },
    bg2: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: "url(/images/bgHorse2.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: 0
    },
    smallGradient: {
        fontSize: 40,
        margin: 0,
        fontWeight: "bold",
        "& span": {
            background: "linear-gradient(to right, rgba(20, 136, 246, 1) 0%, 13.82022500038147%, rgba(101, 4, 243, 1) 27.64045000076294%, 63.82022500038147%, rgba(254, 183, 10, 1) 100%);",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            display: "inline-block",
            lineHeight: 1.2,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
        },
    },
    bigGradient: {
        fontSize: 70,
        margin: 0,
        fontWeight: "bold",
        "& span": {
            background: "linear-gradient(to right, rgba(20, 136, 246, 1) 0%, 13.82022500038147%, rgba(101, 4, 243, 1) 27.64045000076294%, 63.82022500038147%, rgba(254, 183, 10, 1) 100%);",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            display: "inline-block",
            lineHeight: 1.2,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 50,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
    },
    whiteSmall: {
        fontSize: 65,
        fontWeight: "bold",
        margin: 0,
        color: "#fff",
        [theme.breakpoints.down('md')]: {
            fontSize: 45,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
    },
    whiteBig: {
        fontSize: 110,
        fontWeight: "bold",
        margin: 0,
        color: "#fff",
        [theme.breakpoints.down('md')]: {
            fontSize: 80,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 60,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 37,
        },
    },

    line: {
        transition: "all 1.5s",
        whiteSpace: "nowrap",
    },
    box: {
        height: "100vh",
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundImage: "url(/images/bgHorse.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },

}))